<template>
  <section id="blog-page" class="medwell-blog">
    <v-container fluid class="text-center px-10 px-sm-16">
      <v-row
        class="px-sm-6 px-md-12"
        justify="space-around"
        dense
        data-aos="zoom-in-up"
        data-aos-duration="2000"
        data-aos-once="true"
      >
        <v-col cols="12">
          <h5 class="section-tag">The Medwell Blog</h5>
        </v-col>
        <v-col cols="9">
          <h1 class="section-title">Hello, Dear.</h1>
        </v-col>
      </v-row>
      <v-row class="px-3 px-md-12 px-lg-6">
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="blog in blogList"
          :key="blog.nid"
          class="d-flex flex-column my-5 px-0 px-md-14 px-lg-8"
        >
          <div class="card-wrap d-flex flex-column flex-grow-1 mx-auto">
            <v-card
              :href="'/our-blog/' + blog.nid + '/' + getTitle(blog.title)"
              :ripple="false"
              class="mx-auto text-left secondaryColor flex-grow-1"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              <v-img
                class="mx-auto"
                :src="blog.field_cover_image"
                aspect-ratio="1"
              >
              </v-img>
              <div class="card-date">
                <div class="card-date-day">
                  {{ getDate(blog.field_date) }}
                </div>
                <div class="card-date-month-year text-uppercase">
                  {{ getMonth(blog.field_date) }} {{ getYear(blog.field_date) }}
                </div>
              </div>
              <v-card-title class="primaryText--text">
                {{ blog.title }}
              </v-card-title>
              <v-card-text class="primaryText--text" v-html="blog.body">
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  class="hoverColor--text read-more"
                  :href="'/our-blog/' + blog.nid + '/' + getTitle(blog.title)"
                  v-ripple="false"
                >
                  Read More<v-icon right>fas fa-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-0">
      <v-row justify="space-around">
        <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="totalVisibles"
          @input="updatePage"
          color="primaryText"
          v-show="showPagination"
        >
        </v-pagination>
      </v-row>
    </v-container>
    <div id="blog-top-background">
      <BlogTopBackground />
    </div>
    <div id="blog-bottom-background">
      <BlogBottomBackground />
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import BlogTopBackground from "@/components/animatedSVG/Page4/BlogTopBackground";
import BlogBottomBackground from "@/components/animatedSVG/Page4/BlogBottomBackground";
import axios from "axios";

export default {
  components: {
    BlogTopBackground,
    BlogBottomBackground,
  },
  data() {
    return {
      showPagination: false, //hide pagination at initial
      page: 1, //current page
      pageSize: 6, //number of blogs per page
      totalVisibles: 5, //number of visible page buttons
      listCount: 0,
      blogList: [],
      blogs: [],
      title: "",
    };
  },
  created() {
    this.getBlogs();
  },

  methods: {
    getBlogs() {
      axios
        .get("https://cms.medwell.my/api/blog?_format=json")
        .then((response) => {
          this.blogs = response.data;
          let _this = this;
          _this.showRef();
          _this.initPage();
          _this.updatePage(_this.page);
        })
        .catch((error) => console.log(error));
    },
    showRef() {
      if (this.blogs.length > 6) {
        this.showPagination = true; //show pagination if blog items more than 6
      }
    },
    initPage: function () {
      let _this = this;
      _this.listCount = _this.blogs.length;
      if (_this.listCount < _this.pageSize) {
        _this.blogList = _this.blogs;
      } else {
        _this.blogList = _this.blogs.slice(0, _this.pageSize);
      }
    },
    updatePage: function (pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.blogList = _this.blogs.slice(_start, _end);
      window.scrollTo(0, 0);
      // alert(pageIndex);
      // alert(_start);
      // alert(_end);
    },
    getDate(prop) {
      var d = new Date(prop);
      return d.getDate();
    },
    getMonth(prop) {
      const month = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      var m = new Date(prop);
      return month[m.getMonth()];
    },
    getYear(prop) {
      var y = new Date(prop);
      return y.getFullYear();
    },
    getTitle(prop) {
      console.log(prop);
      var title = prop.replace(/\s/g, "-").toLowerCase();
      console.log(title);

      return title;
    },
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
  },
  mounted() {
    var flagscroll = true;
    // Line animation on scroll
    $(window).on("scroll", function () {
      var top = $(window).scrollTop() + $(window).height(),
        isVisible = top > $("#blog-bottom-background").offset().top;
      if (flagscroll == true && isVisible) {
        flagscroll = false;
        $("#blog-bottom-background").toggleClass(
          "blog-bottom-animation",
          isVisible
        );
      }
    });
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

#blog-top-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#blog-bottom-background {
  position: absolute;
  z-index: -100;
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
</style>

<style>
/* Customize hover color of pagination */
.medwell-blog
  .theme--light.v-pagination
  .v-pagination__item:not(.v-pagination__item--active):hover {
  background: #ced5cc;
  color: #ffffff;
}

.medwell-blog .theme--light.v-pagination .v-pagination__navigation:hover {
  background: #ced5cc !important;
  color: #ffffff !important;
}

.medwell-blog
  .theme--light.v-pagination
  .v-pagination__navigation
  .theme--light.v-icon:hover {
  color: #ffffff;
}

/* .medwell-blog .theme--light.v-pagination .v-pagination__navigation:hover {
  background: #ced5cc !important;
  color: #ffffff !important;
}

.medwell-blog
  .theme--light.v-pagination
  .v-pagination__navigation
  .theme--light.v-icon:hover {
  color: #ffffff;
} */

/* Global style - same as blog section in pharmacy page */
.medwell-blog .card-wrap .v-image__image {
  transition: all 0.8s ease;
}

.medwell-blog .card-wrap:hover .v-image__image {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.medwell-blog .v-card {
  border-radius: 30px;
  /* padding: 0px 15px 20px 15px; */
  padding-bottom: 40px;
  position: relative;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: -webkit-transform 0.8s ease-in-out;
  -o-transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
}

.medwell-blog .v-image {
  /* height: 346px; */
  /* height:*/
  border-radius: 30px;
}

.medwell-blog .v-card .card-date {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 20%;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  aspect-ratio: 1 / 1;
}

.medwell-blog .v-card .card-date .card-date-day {
  font-size: 40px;
  line-height: 1.2;
}

.medwell-blog .v-card .card-date .card-date-month-year {
  font-size: 17px;
  line-height: 1.5;
}

.medwell-blog .v-card__title,
.medwell-blog .v-card__text {
  padding-left: 40px;
  padding-right: 40px;
}

.medwell-blog .v-card__title {
  word-break: break-word;
  font-family: "BigCaslon-Rom";
  font-size: 35px;
  font-weight: bold;
  line-height: 40px;
  padding-top: 40px;
}

.medwell-blog .v-card__text {
  word-break: break-word;
  line-height: 30px;
  max-height: 90px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.medwell-blog .v-card__actions {
  padding-bottom: 0;
  padding-left: 30px;
}

.medwell-blog .v-card__text,
.medwell-blog .read-more .v-btn__content {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
}

.medwell-blog .read-more .v-btn__content {
  letter-spacing: 0;
  font-weight: normal;
}

.medwell-blog .card-wrap:hover .v-card {
  -webkit-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-box-shadow: 0 8px 8px 5px rgba(22, 22, 26, 0.18);
  box-shadow: 0 8px 8px 5px rgba(22, 22, 26, 0.18);
}

.medwell-blog .card-wrap:hover .v-card__title {
  color: #a56c6a !important;
}

.medwell-blog .card-wrap:hover .card-date {
  background-color: #a56c6a;
}

.medwell-blog .card-wrap:hover .read-more .v-btn__content {
  /* font-weight: bold; */
  text-shadow: -0.5px -0.5px 0 #a56c6a, 0.5px -0.5px 0 #a56c6a,
    -0.5px 0.5px 0 #a56c6a, 0.5px 0.5px 0 #a56c6a;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.medwell-blog .v-card--link:before {
  background: transparent;
}

@media (max-width: 1903px) {
  .medwell-blog .v-card {
    padding-bottom: 25px;
  }

  .medwell-blog .v-card .card-date .card-date-day {
    font-size: 33px;
  }

  .medwell-blog .v-card .card-date .card-date-month-year {
    font-size: 11px;
  }

  .medwell-blog .v-card__title,
  .medwell-blog .v-card__text {
    padding-left: 25px;
    padding-right: 25px;
  }

  .medwell-blog .v-card__title {
    font-size: 26px;
    padding-top: 25px;
    line-height: 35px;
  }

  .medwell-blog .v-card__text {
    line-height: 25px;
    max-height: 75px;
  }

  .medwell-blog .v-card__text,
  .medwell-blog .read-more .v-btn__content {
    font-size: 19px;
  }

  .medwell-blog .v-card__actions {
    padding-left: 15px;
  }
}

@media (max-width: 1263px) {
  .medwell-blog .v-card .card-date .card-date-day {
    font-size: 31px;
  }

  .medwell-blog .v-card .card-date .card-date-month-year {
    font-size: 10px;
  }

  .medwell-blog .v-card__title {
    font-size: 24px;
  }

  .medwell-blog .v-card__text,
  .medwell-blog .read-more .v-btn__content {
    font-size: 17px;
  }
}

@media (max-width: 959px) {
  .medwell-blog .card-wrap {
    max-width: 400px;
  }

  .medwell-blog .v-card__title {
    font-size: 23px;
    line-height: 30px;
  }

  .medwell-blog .v-card__text,
  .medwell-blog .read-more .v-btn__content {
    font-size: 16px;
  }
}

@media (max-width: 599px) {
  .medwell-blog .card-wrap {
    max-width: 100%;
  }
}

@media (max-width: 350px) {
  .medwell-blog .v-card .card-date .card-date-day {
    font-size: 30px;
  }

  .medwell-blog .v-card .card-date .card-date-month-year {
    font-size: 8px;
  }

  .medwell-blog .v-card__title {
    padding-top: 25px;
  }

  .medwell-blog .v-card__text {
    line-height: 20px;
    max-height: 60px;
  }
}
</style>
