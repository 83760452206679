import Vue from 'vue'
import VueRouter from 'vue-router'
import Pharmacy from '../views/Pharmacy.vue'
import Story from '../views/Story.vue'
import Doctors from '../views/Doctors.vue'
import Thankyou from '../views/Thankyou.vue'
import Blog from '../views/Blog.vue'
import BlogDetails from '../views/BlogDetails.vue'
import TermsOfService from '../views/TermsOfService.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Pharmacy',
    component: Pharmacy,
    meta: {
      title: 'Pharmacy',
      metaTags: [
        {
          name: 'description',
          content: 'Home page of Medwell. To your wellbeing. Evidence-based medicine and wellness to help you feel good and do more. Consult our team of medical professionals.'
        },
        {
          property: 'og:description',
          content: 'Home page of Medwell. To your wellbeing. Evidence-based medicine and wellness to help you feel good and do more. Consult our team of medical professionals.'
        }
      ]
    }
  },
  {
    path: '/our-story',
    name: 'Our Story',
    component: Story,
    meta: {
      title: 'Our Story',
      metaTags: [
        {
          name: 'description',
          content: 'Our Story page of Medwell. The Smarter, Simpler Pharmacy. We are a new kind of pharmacy, designed to help you lead a better life.'
        },
        {
          property: 'og:description',
          content: 'Our Story page of Medwell. The Smarter, Simpler Pharmacy. We are a new kind of pharmacy, designed to help you lead a better life.'
        }
      ]
    }
  },
  {
    path: '/for-doctors',
    name: 'For Doctors',
    component: Doctors,
    meta: {
      title: 'For Doctors',
      metaTags: [
        {
          name: 'description',
          content: 'For Doctors page of Medwell. Let’s Work Together. Join us in our effort to improve the system for a better future.'
        },
        {
          property: 'og:description',
          content: 'For Doctors page of Medwell. Let’s Work Together. Join us in our effort to improve the system for a better future.'
        }
      ]
    }
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: Thankyou,
    meta: {
      hideNavigation: true,
      title: 'Thank You',
    }
  },
  {
    path: '/our-blog',
    name: 'Our Blog',
    component: Blog,
    meta: {
      title: 'Our Blog',
      metaTags: [
        {
          name: 'description',
          content: 'Our Blog page of Medwell. Medwell blog shares information about public health and medication safety.'
        },
        {
          property: 'og:description',
          content: 'Our Blog page of Medwell. Medwell blog shares information about public health and medication safety.'
        }
      ]
    }
  },
  {
    path: '/our-blog/:blogId/:blogTitle',
    name: 'Our Blog Details',
    component: BlogDetails,
    props: true,
  },
  {
    path: '/terms-of-service',
    name: 'Terms Of Service',
    component: TermsOfService,
    meta: {
      title: 'Terms Of Service'
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy'
    }
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: PageNotFound,
    meta: {
      hideNavigation: true,
      title: 'Page Not Found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // if (to.name === 'Pharmacy') {
  //   document.title = `${process.env.VUE_APP_TITLE} | ${to.name}`
  // } else {
  //   document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`
  // }
  // next()
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    if (nearestWithTitle.meta.title === 'Pharmacy') {
      document.title = `${process.env.VUE_APP_TITLE} | ` + nearestWithTitle.meta.title;
    } else {
      document.title = nearestWithTitle.meta.title + ` | ${process.env.VUE_APP_TITLE}`;
    }
    // document.title = nearestWithTitle.meta.title + ` | ${process.env.VUE_APP_TITLE}`;
  } else if (previousNearestWithMeta) {
    if (previousNearestWithMeta.meta.title === 'Pharmacy') {
      document.title = `${process.env.VUE_APP_TITLE} | ` + previousNearestWithMeta.meta.title;
    } else {
      document.title = previousNearestWithMeta.meta.title + ` | ${process.env.VUE_APP_TITLE}`;
    }
    // document.title = previousNearestWithMeta.meta.title + ` | ${process.env.VUE_APP_TITLE}`;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
})

export default router
