<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="40.414"
    height="41.79"
    viewBox="0 0 41.414 42.79"
  >
    <g
      id="Group_98"
      data-name="Group 98"
      transform="translate(-276.729 -1071.238)"
    >
      <path
        id="Path_221"
        data-name="Path 221"
        d="M345.009,425.886l-4-4-3.393,3.374-8.337,8.288s-1.668,6.7-2.132,6.239,6.04-1.97,6.04-1.97l8.535-8.609Zm-16.2-19.8v-4.4l-5.057.027V403.8h-6.313V401.68h-5.055v4.4Zm6.47,14.061v-16.27h-6.47v-2.167h-5.057V399.54h-6.313v2.167l-5.055-.027v2.164l-6.43-.046v10.951l-.081,24.86s9.842-.02,14.052-.02h3.206"
        transform="translate(-28.398 672.448)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_224"
        data-name="Path 224"
        d="M305.837,439.855c1.494,0,7.358.011,9.487.014"
        transform="translate(-24.22 643.662)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_225"
        data-name="Path 225"
        d="M305.837,439.855c3.272,0,16.108.011,20.768.014"
        transform="translate(-24.22 647.964)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_226"
        data-name="Path 226"
        d="M305.837,439.855c3.118,0,15.353.011,19.8.014"
        transform="translate(-24.22 652.267)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_227"
        data-name="Path 227"
        d="M305.837,439.855c2.851,0,14.035.011,18.1.014"
        transform="translate(-24.22 656.57)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_228"
        data-name="Path 228"
        d="M305.837,439.855c2.482.019,12.222.045,15.758.056"
        transform="translate(-24.22 660.83)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_229"
        data-name="Path 229"
        d="M305.837,439.855c1.494.019,7.358.045,9.487.056"
        transform="translate(-24.22 665.175)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M305.837,439.855h0c.781.019,3.7.045,4.77.056"
        transform="translate(396.624 578.896) rotate(45)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
      <path
        id="Path_232"
        data-name="Path 232"
        d="M305.837,439.855c.842.019,4.145.045,5.344.056"
        transform="translate(404.217 570.321) rotate(45)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="bevel"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.kkm-animation.animate {
  stroke-dasharray: 120;
  stroke-dashoffset: 0;
  -webkit-animation: animate-kkm ease-out 3s forwards;
  animation: animate-kkm ease-out 3s forwards;
}

@-webkit-keyframes animate-kkm {
  from {
    stroke-dashoffset: 120;
  }
}

@keyframes animate-kkm {
  from {
    stroke-dashoffset: 120;
  }
}
</style>