<template>
  <svg
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1490.69"
    height="828.434"
    viewBox="0 0 1490.69 828.434"
  >
    <path
      id="Path_226"
      data-name="Path 226"
      d="M131.855,209.915C179.362,236.376,249.759,287,249.759,287s75.615,56.778,145.935,103.126c50.047,32.986,96.5,59.12,108.045,65.138,27.747,14.467,12.763,6.6,38.787,17.94s44.124,18.879,66.253,27.564c39.581,12.287,79.378,25.921,110.53,33.786C752.433,542.921,829.531,556.774,866.4,561s85.578,6.262,85.578,6.262,38.017,2.146,81.53.892A967.975,967.975,0,0,0,1154.3,556.306c1.592-.267,38.593-8.025,72.063-20.613,7.406-3.268,40.995-13.451,70.682-33.3,34.779-28.735,62.058-58.024,83.772-81.409,19.157-21.063,47.534-58.643,65.036-93.649,13.437-26.875,17.351-44.622,20.9-57.765s2.448-16.236,4.152-29.543c.3-2.332-.1-14.573-3.086-26.581s-4.731-14.876-8.808-22.27c-5.538-7.536-12.558-15.982-20.748-22.243a244.3,244.3,0,0,0-29.571-16.093s-30.822-12.391-59.583-16.9-59.948-2.172-59.948-2.172-30.007.43-56.961,11.759-32.088,16.569-48.5,30.649c-5.475,4.7-18.225,16.892-27.345,44.189s-9.135,65-9.135,65a174.538,174.538,0,0,0,7.1,45.187c4.307,14.04,9.354,29.117,13.677,39.387,2.934,6.972,25.239,54.92,55.367,91.358s64.012,72.787,93.74,105.4c4.167,4.571,20.392,21.6,43.146,41.608s22.331,23.847,62.678,53,73.158,46.73,98.713,63.608"
      transform="translate(-133.579 106.576) rotate(-8)"
      fill="none"
      stroke="#ced5cc"
      stroke-width="50"
      opacity="0.30"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  width: 100%;
  height: auto;
  transform: scale(1.2) translate(-3%, 30%);
}

@media (max-width: 1263px) {
  svg {
    transform: scale(1.3) translate(-5%, 32%);
  }
}

@media (max-width: 959px) {
  svg {
    transform: scale(1.8) translate(-9%, 32%);
  }
}

@media (max-width: 767.98px) {
  svg {
    transform: scale(2.3) translate(-15%, 28%);
  }
}

@media (max-width: 599px) {
  svg {
    transform: scale(2.6) translate(-20%, 20%);
  }
}

@media (max-width: 350px) {
  svg {
    transform: scale(2.8) translate(-22%, -9%);
  }
}
</style>

<style>
/* Animation */
.blog-details-center-animation {
  stroke-dasharray: 2658;
  stroke-dashoffset: 0;
  -webkit-animation: animate-Path_226 4s ease-in forwards;
  animation: animate-Path_226 4s ease-in forwards;
}

@-webkit-keyframes animate-Path_226 {
  from {
    stroke-dashoffset: 2658;
  }
}

@keyframes animate-Path_226 {
  from {
    stroke-dashoffset: 2658;
  }
}
</style>