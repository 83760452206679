<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="36.771"
    height="43.487"
    viewBox="0 0 37.771 44.487"
  >
    <g
      id="Group_94"
      data-name="Group 94"
      transform="translate(-294.5 -1384.193)"
    >
      <path
        id="Path_124"
        data-name="Path 124"
        d="M175.68,171.253h-3.873a.551.551,0,0,0-.553.553v3.873h-3.32v-3.873a.551.551,0,0,0-.553-.553h-3.873v-3.32h3.873c.307,0,.553-.246.553-4.427h3.351v3.873c0,.307.246.553,4.427.553v3.32Z"
        transform="translate(143.6 1235.684)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_215"
        data-name="Path 215"
        d="M49.886,42.486a.815.815,0,0,1-.252-.039C43.484,40.459,32,33.5,32,25.669V6.2a.881.881,0,0,1,.606-.848L49.64.037a.823.823,0,0,1,.489,0L67.163,5.348a.882.882,0,0,1,.608.848V25.669c0,7.828-11.484,14.789-17.634,16.778A.815.815,0,0,1,49.886,42.486Z"
        transform="translate(263 1384.694)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.medication-safety-animation.animate {
  stroke-dasharray: 130;
  stroke-dashoffset: 0;
  -webkit-animation: animate-medication-safety ease-out 3s forwards;
  animation: animate-medication-safety ease-out 3s forwards;
}

@-webkit-keyframes animate-medication-safety {
  from {
    stroke-dashoffset: 130;
  }
}

@keyframes animate-medication-safety {
  from {
    stroke-dashoffset: 130;
  }
}
</style>