<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="44.711"
    height="44.254"
    viewBox="0 0 45.711 45.254"
  >
    <g
      id="Group_96"
      data-name="Group 96"
      transform="translate(-1148.688 -1143.775)"
    >
      <path
        id="Path_219"
        data-name="Path 219"
        d="M46.114,24.687l-3.478,2.469.052-7.815A18.073,18.073,0,0,0,40.25,14.5c-.179-.286-.552-1-.935-1.521-1.14-2.02-.676-1.826-1.687-1.792-3.27.111-9.14,0-9.14,0h9.14V8.794c.29-.1-17.325.412-16.867,0,0,0,3.585.1,7.728,0s7.326.031,9.14,0,1.687-2.249,1.687-2.249V2.53A2.533,2.533,0,0,0,36.785,0H21.6a2.533,2.533,0,0,0-2.53,2.53V6.409a2.534,2.534,0,0,0,1.687,2.385v2.394l-4.217,5.622a4.246,4.246,0,0,0-.843,2.53V35.1h0c0,2.325,1.892,4.794,4.217,4.794H33.471c1.891,3.17,3.821,6.448,16.926-2.036l-7.761-10.7,7.761,10.7c3.2-1.849,11.783-5.837,8.214-12.338C55.807,20.674,49.689,22.458,46.114,24.687Zm-3.426-4.638c.109-.146-27.257.36-26.987,0Zm0,7.107h-.052s-6.229,4.149-7.618,4.952A7.647,7.647,0,0,0,33.01,33.76a3.417,3.417,0,0,0-.8,1.336l-.022.057c-.769,1.964,1.283,4.737,1.283,4.737"
        transform="translate(1133.501 1144.275)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_220"
        data-name="Path 220"
        d="M126.539,278.046h8.313"
        transform="translate(1030.94 893.469)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_221"
        data-name="Path 221"
        d="M130.756,286.479v-8.433"
        transform="translate(1030.88 889.252)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_225"
        data-name="Path 225"
        d="M48.75,29.363c-1.433-.075,12.519,0,16.441,0"
        transform="translate(1100.636 1149.275)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.genuine-medications-animation.animate {
  stroke-dasharray: 240;
  stroke-dashoffset: 0;
  -webkit-animation: animate-genuine-medications ease-out 3s forwards;
  animation: animate-genuine-medications ease-out 3s forwards;
}

@-webkit-keyframes animate-genuine-medications {
  from {
    stroke-dashoffset: 240;
  }
}

@keyframes animate-genuine-medications {
  from {
    stroke-dashoffset: 240;
  }
}
</style>
