<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="46.704"
    height="46.025"
    viewBox="0 0 47.704 47.025"
  >
    <g
      id="Group_99"
      data-name="Group 99"
      transform="translate(-586.174 -1296.336)"
    >
      <path
        id="Path_168"
        data-name="Path 168"
        d="M293.476,444.923a.961.961,0,0,1-.574-.19c-.486-.363-11.9-8.964-11.9-15.876a6.971,6.971,0,0,1,12.476-4.272,6.971,6.971,0,0,1,12.476,4.272c0,6.912-11.417,15.513-11.9,15.876a.961.961,0,0,1-.574.19Z"
        transform="translate(326.175 896.688)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_171"
        data-name="Path 171"
        d="M213.818,279.809a2.863,2.863,0,1,0,2.9,2.862,2.885,2.885,0,0,0-2.9-2.862Zm0,0"
        transform="translate(388.629 1023.176)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_172"
        data-name="Path 172"
        d="M191.363,363.7a4.4,4.4,0,0,0-1.518-3.036,3.462,3.462,0,0,0-2.07-.852H181.8c-1.587,0-3.891,1.611-3.891,3.2V365.7"
        transform="translate(417.811 952.879)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2"
      />
      <path
        id="Path_173"
        data-name="Path 173"
        d="M124.745,270.2H109.081c-2.645,0-3.657-2.136-3.657-4.78V235.883c0-2.645,1.73-4.14,4.375-4.14h22.606c2.645,0,3.864,1.5,3.864,4.14v13.456"
        transform="translate(481.6 1065.443)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2"
      />
      <path
        id="Path_233"
        data-name="Path 233"
        d="M188.807,359.809h-9.73"
        transform="translate(413.811 963.747)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2"
      />
      <path
        id="Path_234"
        data-name="Path 234"
        d="M191.434,359.809H181.8"
        transform="translate(411.012 969.341)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.hassle-free-experience-animation.animate {
  stroke-dasharray: 110;
  stroke-dashoffset: 0;
  -webkit-animation: animate-hassle-free-experience ease-out 3s forwards;
  animation: animate-hassle-free-experience ease-out 3s forwards;
}

@-webkit-keyframes animate-hassle-free-experience {
  from {
    stroke-dashoffset: 110;
  }
}

@keyframes animate-hassle-free-experience {
  from {
    stroke-dashoffset: 110;
  }
}
</style>