<template>
  <section class="blog-details" v-if="blog">
    <v-container
      fluid
      class="px-10 px-sm-16 pt-16"
      data-aos="fade-up"
      data-aos-duration="2000"
      data-aos-once="true"
    >
      <v-container fluid class="px-0 px-md-12">
        <v-img
          class="mx-auto"
          :src="blog[0].field_cover_image"
          aspect-ratio="1"
        >
        </v-img>
        <h1 class="mt-10">{{ blog[0].title }}</h1>
        <v-row dense class="grey--text post-text pt-2">
          <div class="mr-4 pt-1">
            <v-icon color="grey lighten-1" class="mb-1" size="22px"
              >mdi-account</v-icon
            >
            Authored by {{ blog[0].field_author }}
          </div>
          <div class="pt-1">
            <v-icon color="grey lighten-1" class="mb-1" size="22px"
              >mdi-calendar-month</v-icon
            >
            Posted on {{ blog[0].field_date }}
          </div>
        </v-row>

        <v-container
          v-html="blog[0].body"
          class="text-left text-md-justify my-12 pa-0 section-text"
        ></v-container>
        <h2 v-if="blog[0].field_reference">References</h2>
        <div v-html="blog[0].field_reference" class="reference-text"></div>
      </v-container>
    </v-container>

    <div id="blog-details-top-background">
      <BlogDetailsTopBackground />
    </div>
    <div id="blog-details-center-background">
      <BlogDetailsCenterBackground />
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import BlogDetailsTopBackground from "@/components/animatedSVG/PageBlogDetails/BlogDetailsTopBackground";
import BlogDetailsCenterBackground from "@/components/animatedSVG/PageBlogDetails/BlogDetailsCenterBackground";
import axios from "axios";

export default {
  props: ["blogId", "blogTitle"],
  components: {
    BlogDetailsTopBackground,
    BlogDetailsCenterBackground,
  },
  data() {
    return {
      blog: null,
    };
  },
  mounted() {
    axios
      .get(
        "https://cms.medwell.my/api/blog/" + `${this.blogId}` + "?_format=json"
      )
      .then((response) => {
        this.blog = response.data;
        document.title = this.blog[0].title + ` | ${process.env.VUE_APP_TITLE}`; //get the title of the blog after filtering by params blogId
      })
      .catch((error) => console.log(error));
    // document.title = this.blogTitle + ` | ${process.env.VUE_APP_TITLE}`; //direct retrieve params blogTitle

    var flagscroll = true;
    // Line animation on scroll
    $(window).on("scroll", function () {
      var top = $(window).scrollTop() + $(window).height(),
        isVisible = top > $("#blog-details-center-background").offset().top;
      if (flagscroll == true && isVisible) {
        flagscroll = false;
        $("#blog-details-center-background").toggleClass(
          "blog-details-center-animation",
          isVisible
        );
      }
    });
  },
  methods: {},
};
</script>

<style scoped>
#blog-details-top-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#blog-details-center-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-image {
  width: 100%;
  height: 60vh;
  border-radius: 30px;
}

h1,
h2 {
  font-family: "BigCaslon-Rom";
  letter-spacing: 1px;
  color: #2b2b2b;
}

.post-text,
.reference-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #2b2b2b;
}

.section-text >>> a {
  color: #a56c6a;
}

.reference-text >>> a {
  color: #2b2b2b;
}

.section-text >>> a,
.reference-text >>> a {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.section-text >>> a:hover,
.reference-text >>> a:hover {
  text-decoration: underline;
}

.section-text >>> table,
.section-text >>> th,
.section-text >>> td {
  border: 1px solid #ced5cc;
  border-collapse: collapse;
}

.section-text >>> table {
  width: 100%;
  display: block;
  overflow-x: auto;
}

.section-text >>> th {
  text-align: center;
  background-color: #ced5cc;
}

.section-text >>> th,
.section-text >>> td {
  padding: 15px;
}

.section-text >>> li {
  margin-left: 20px;
}

@media (min-width: 1904px) {
  .v-image {
    height: 40vh;
  }
}

@media (min-width: 1264px) {
  .blog-details > .container {
    width: 70%;
  }

  h1 {
    font-size: 34px;
  }
}

@media (max-width: 1263px) {
  .post-text,
  .reference-text {
    font-size: 14px;
  }
}

@media (max-width: 959px) {
  .v-image {
    height: 50vh;
  }
}

@media (max-width: 599px) {
  .v-image {
    height: 40vh;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 30px;
    word-break: break-word;
  }
}
</style>
