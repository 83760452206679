<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="43.667"
    height="43.667"
    viewBox="0 0 44.667 44.667"
  >
    <path
      id="Path_113"
      data-name="Path 113"
      d="M312.122,433.162l-6.8-3.7a2.073,2.073,0,0,1-1.038-1.837V425q.245-.33.51-.739a18.212,18.212,0,0,0,2.1-4.5,3.1,3.1,0,0,0,1.656-2.8v-3.107a3.253,3.253,0,0,0-.712-2.033v-4.129a7.4,7.4,0,0,0-1.486-5.061c-1.461-1.818-3.829-2.738-7.044-2.738s-5.588.92-7.049,2.738a7.1,7.1,0,0,0-1.466,3.728,8.625,8.625,0,0,0-4.288-1.034,7.448,7.448,0,0,0-7.823,6.981v3.553a2.83,2.83,0,0,0-.708,1.807v2.678a2.875,2.875,0,0,0,.93,2.144,12.212,12.212,0,0,0,2.59,4.879v2.125a1.715,1.715,0,0,1-.829,1.489l-4.732,3.235a6.252,6.252,0,0,0-2.938,5.4v2.947h42.667v-3.136a7.049,7.049,0,0,0-3.545-6.258ZM290.8,406.356l-.02,5.462a3.253,3.253,0,0,0-.712,2.033v3.107a3.27,3.27,0,0,0,.073.688,2.4,2.4,0,0,0,.084.262,2.853,2.853,0,0,0,.125.386l0,0a3.066,3.066,0,0,0,.78,1.069c0,.015.007.03.011.042.042.177.087.356.135.53l.059.2.031.11c.024.083.049.167.076.246.042.137.087.276.139.431.021.061.045.121.069.182.056.163.115.318.178.473.013.038.028.079.041.113l.042.1c.018.046.038.087.056.129q.1.25.208.485c.011.022.021.048.035.076.045.094.087.189.132.28.076.159.153.31.229.458.037.069.073.141.108.208.1.19.2.364.3.527.02.038.042.073.062.107.17.291.319.526.444.712.031.046.059.091.087.129.017.022.038.056.052.076v2.568a2.085,2.085,0,0,1-.979,1.8l-1.883,1.121-.461.272c-.406.242-.791.478-1.407.87a26.413,26.413,0,0,0-3.044,2.221A9.624,9.624,0,0,0,283.7,436.3a3.845,3.845,0,0,0-.609,1.563,39.689,39.689,0,0,0-.189,4.693"
      transform="translate(-272.5 -399.39)"
      fill="none"
      stroke="#1B1B1B"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.highly-qualified-team-animation.animate {
  stroke-dasharray: 160;
  stroke-dashoffset: 0;
  -webkit-animation: animate-highly-qualified-team ease-out 3s forwards;
  animation: animate-highly-qualified-team ease-out 3s forwards;
}

@-webkit-keyframes animate-highly-qualified-team {
  from {
    stroke-dashoffset: 160;
  }
}

@keyframes animate-highly-qualified-team {
  from {
    stroke-dashoffset: 160;
  }
}
</style>