<template>
  <v-footer padless class="primaryText secondaryColor--text">
    <div id="footer-wave"><FooterWave /></div>
    <v-container class="text-center">
      <v-row>
        <v-col cols="12" class="footer-title py-0">Let's get started.</v-col>
        <v-col cols="12" class="footer-info font-weight-bold py-0 mb-4">
          Take charge of your wellbeing today.
        </v-col>
        <v-col cols="12" class="mb-13 mb-sm-5">
          <v-btn
            rounded
            outlined
            href="/#our-services"
            color="secondaryColor"
            class="btn-book-consultation font-weight-bold pa-5"
          >
            Book A Consultation
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-between" class="first text-sm-left px-9">
        <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="my-2 my-sm-12">
          <h5>Need Help?</h5>
          <p class="footer-contact mb-0">
            Call or text
            <a href="tel:+6011-28888 695" class="footer-tel">+6011-28888 695</a>
            <br />
            Email us at <a href="mailto:hello@medwell.my">hello@medwell.my</a>
          </p>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="3" class="my-2 my-sm-12">
          <h5>Company Info</h5>
          <ul class="list-unstyled">
            <li v-for="link in links" :key="link.text">
              <!-- <router-link :to="link.route">{{ link.text }}</router-link> -->
              <a :href="link.route">{{ link.text }}</a>
              <!-- <a :href="$router.resolve(link.route).href">{{ link.text }}</a> -->
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="my-2 my-sm-12">
          <h5>Social Media</h5>
          <v-btn
            fab
            v-for="social in socials"
            :key="social.icon"
            :href="social.route"
            target="_blank"
            class="social-media mr-2 mb-2"
            width="34px"
            height="34px"
            color="secondaryColor"
          >
            <v-icon size="22px" class="primaryText--text">
              {{ social.icon }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-between" class="second px-9"
        ><v-col
          cols="12"
          sm="5"
          class="text-center text-sm-left mt-4 mt-sm-5 my-10"
        >
          <v-row>
            <v-col class="pb-0">
              <a
                href="/"
                class="d-flex align-center justify-center justify-sm-start"
              >
                <v-img
                  contain
                  max-height="23"
                  max-width="38"
                  src="../assets/img/LogoMedwellWhite.png"
                  alt="logo"
                  class="mb-1"
                >
                </v-img>
                <span class="footer-company-name mx-2">Medwell</span>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <a href="/terms-of-service">Terms of Service</a> |
              <a href="/privacy-policy">Privacy Policy</a>
              <!-- | <a href="#">HIPAA Policy</a> -->
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-center text-sm-right mt-n3 mt-sm-5 my-10"
        >
          <p class="footer-info">
            © 2021 Medwell Pharmacy<br />
            Lot 13-14, Block 11, MTLD Milan Square, Jalan Wan Alwi,<br />
            93350 Kuching, Sarawak, Malaysia.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import FooterWave from "@/components/animatedSVG/FooterWave";

export default {
  components: { FooterWave },
  data() {
    return {
      socials: [
        {
          icon: "fab fa-facebook-f",
          route: "https://www.facebook.com/medwellmy/",
        },
        {
          icon: "fab fa-instagram",
          route: "https://www.instagram.com/medwellmy/",
        },
        {
          icon: "fab fa-linkedin-in",
          route: "https://www.linkedin.com/company/medwellpharmacy/about/",
        },
      ],
      links: [
        { text: "Pharmacy", route: "/" },
        { text: "Our Story", route: "/our-story" },
        { text: "Our Blog", route: "/our-blog" },
        { text: "For Doctors", route: "/for-doctors" },
      ],
    };
  },
};
</script>

<style scoped>
footer {
  margin-top: 175px;
  z-index: 0;
}

#footer-wave {
  width: 100%;
  top: 0;
  position: absolute;
}

.v-footer .footer-title {
  font-family: "BigCaslon-Rom";
  font-size: 42px;
}

.v-footer .footer-info {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.v-footer .btn-book-consultation {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  letter-spacing: 0;
}

.v-footer .btn-book-consultation:hover {
  background-color: #f0f2f0;
  color: #2b2b2b !important;
}

.v-footer h5 {
  font-family: "BigCaslon-Rom";
  font-size: 35px;
  font-weight: 400;
  padding-bottom: 30px;
}

.v-footer .footer-contact,
.v-footer a {
  font-family: "Open Sans", sans-serif;
  color: #f0f2f0;
}

.v-footer .footer-tel {
  display: inline-block;
}

.v-footer a {
  text-decoration: none;
}

.v-footer a:hover {
  color: grey;
}

.v-footer .second a:hover .footer-company-name {
  color: #f0f2f0;
}

.v-footer .footer-contact,
.v-footer .first a {
  font-size: 20px;
  font-weight: bold;
}

.v-footer .second a {
  font-size: 16px;
}

.v-footer .list-unstyled {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.v-footer .social-media:hover {
  background-color: grey !important;
}

.v-footer .social-media:hover .v-icon {
  color: #f0f2f0 !important;
  filter: drop-shadow(1.95px 1.95px 1.6px #494a4a);
}

.v-footer .footer-company-name {
  font-family: "Hatton-Medium";
  font-size: 27px;
}

@media (max-width: 599px) {
  footer {
    margin-top: 100px;
  }

  .v-footer h5 {
    font-size: 32px;
    padding-bottom: 10px;
  }

  .v-footer .footer-contact,
  .v-footer .first a {
    font-size: 18px;
  }
}
</style>
