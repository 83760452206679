<template>
  <div class="pharmacy">
    <!--Section Banner-->
    <section class="hero d-flex flex-column justify-center">
      <v-container
        fluid
        class="px-sm-8 px-6 text-center"
        data-aos="fade-down"
        data-aos-duration="2000"
        data-aos-once="true"
      >
        <v-row justify="space-around" dense no-gutters>
          <v-col cols="12">
            <h1 class="hero-title">To your wellbeing.</h1>
          </v-col>
        </v-row>
        <v-row justify="space-around" dense no-gutters>
          <v-col sm="12" cols="9">
            <h2 class="section-subtitle">
              Evidence-based medicine and wellness to help
              <br class="d-none d-sm-flex" />
              you feel good and do more.
            </h2>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col>
            <v-btn text href="#our-services" class="btn-custom mb-4">
              Book A Consultation
            </v-btn>
            <div id="text-book-consultation">
              Consult our team of medical professionals
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div id="hero-1-background"><Hero1Background /></div>
    </section>

    <!--Section 2 - ICON ANIMATION SECTION-->
    <section id="icon-list">
      <v-container fluid class="text-center px-10 px-sm-16">
        <v-row dense>
          <v-col cols="11" lg="7" class="mx-auto px-5">
            <h1
              class="section-title"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-once="true"
            >
              It’s OK to have high expectations.
            </h1>
          </v-col>
        </v-row>
        <v-row class="px-sm-6 px-md-12 pt-6" dense>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mb-lg-8"
            v-for="icon in icons"
            :key="icon.title"
          >
            <v-card
              flat
              class="mx-auto text-center transparent"
              max-width="380"
            >
              <div class="img-icon" :class="icon.animation">
                <component :is="icon.componentName"></component>
              </div>

              <v-card-title
                class="primaryText--text justify-center"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-once="true"
              >
                {{ icon.title }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!--Section 3 - OUR PRACTICE SECTION-->
    <section id="our-practice">
      <v-container fluid class="px-sm-16 px-10">
        <!--Show Only On MD and down-->
        <v-row dense class="px-md-12 px-sm-6 hidden-lg-and-up">
          <v-col
            cols="12"
            class="text-center"
            data-aos="fade-down"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <h5 class="section-tag">Our Practice</h5>
            <h1 class="section-title">Interprofessional Collaboration</h1>
          </v-col>
        </v-row>
        <!--End MD and down-->

        <v-row dense align="center" class="px-md-12 px-sm-6">
          <v-col
            lg="7"
            cols="12"
            class="pr-lg-14 text-lg-left text-center"
            :order="$vuetify.breakpoint.mdAndDown ? 2 : 1"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <!--Show Only On LG and up-->
            <div class="hidden-md-and-down">
              <h5 class="section-tag">Our Practice</h5>
              <h1 class="section-title">Interprofessional Collaboration</h1>
            </div>
            <!--End LG and up-->

            <p class="section-subtitle mt-lg-0 mb-lg-15 mt-6 mb-10">
              We utilize our team of highly qualified and certified pharmacists
              and medical professionals to ensure that you are well taken cared
              of.
            </p>
            <div>
              <v-dialog v-model="dialog" max-width="850px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="btn-custom" v-bind="attrs" v-on="on">
                    LEARN MORE
                  </v-btn>
                </template>
                <v-card class="modal px-sm-16 px-10 py-16" rounded="0">
                  <v-btn
                    text
                    v-ripple="false"
                    @click="dialog = false"
                    class="close"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <h5 class="section-tag text-center mt-6">
                    Inter-Professional Collaboration (IPC)
                  </h5>
                  <h1 class="section-title text-center mb-12">
                    Teamwork Makes The Dreamwork
                  </h1>
                  <div class="section-text">
                    <p>
                      The World Health Organization defines interprofessional
                      collaboration (IPC) in healthcare as “multiple health
                      workers from different professional backgrounds work[ing]
                      together with patients, families, carers (caregivers), and
                      communities to deliver the highest quality of care.”
                    </p>
                    <p>
                      Numerous studies across the world have proven that IPC
                      leads to an improvement in health care, which calls for
                      increased IPC nationally and internally through
                      interprofessional education in health professions.
                    </p>
                    <p class="my-10"><b>References</b></p>
                    <p>
                      <u>
                        Bart N. Green, Claire D. Johnson; Interprofessional
                        collaboration in research, education, and clinical
                        practice: working together for a better future. J
                        Chiropr Educ 1 March 2015; 29 (1): 1–10.
                        <br /><br />
                        Duru, O. K., Schmittdiel, J. A., Dyer, W. T., Parker, M.
                        M., Uratsu, C. S., Chan, J., & Karter, A. J. (2010).
                        Mail-order pharmacy use and adherence to
                        diabetes-related medications. The American journal of
                        managed care, 16(1), 33–40.
                        <br /><br />
                        Lee, J. K., Grace, K. A., & Taylor, A. J. (2006). Effect
                        of a pharmacy care program on medication adherence and
                        persistence, blood pressure, and low-density lipoprotein
                        cholesterol: a randomized controlled trial. JAMA,
                        296(21), 2563–2571.
                        <br /><br />
                        Viswanathan, M., Golin, C. E., Jones, C. D., Ashok, M.,
                        Blalock, S. J., Wines, R. C., Coker-Schwimmer, E. J.,
                        Rosen, D. L., Sista, P., & Lohr, K. N. (2012).
                        Interventions to improve adherence to self-administered
                        medications for chronic diseases in the United States: a
                        systematic review. Annals of internal medicine, 157(11),
                        785–795.
                      </u>
                    </p>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col
            lg="5"
            cols="12"
            :order="$vuetify.breakpoint.mdAndDown ? 1 : 2"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <v-img
              src="../assets/img/OurPractice1.png"
              alt="OurPractice1"
              max-height="550"
              max-width="470"
              aspect-ratio="0.88"
              class="pic mx-auto"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
      <div id="our-practice-background"><OurPracticeBackground /></div>
    </section>

    <!--Section 4 - OUR TEAM SECTION-->
    <section id="our-team">
      <v-container fluid class="px-sm-16 px-10 text-center">
        <v-row dense class="px-md-12 px-sm-6">
          <v-col
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <h5 class="section-tag">Our Team</h5>
            <h1 class="section-title">A new kind of care.</h1>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="slider text-center">
        <VueSlickCarousel v-bind="settingsTeam">
          <v-container
            v-for="(people, index) in team"
            :key="index"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <button @click.stop="people.dialog = true" class="slider-item">
              <v-avatar :size="$vuetify.breakpoint.xs ? 170 : 218">
                <img
                  :src="require(`@/assets/img/${people.src}`)"
                  :alt="people.alt"
                />
              </v-avatar>
              <div class="slider-text mt-4">
                <div class="people-name">{{ people.name }}</div>
                <div class="section-text">{{ people.job }}</div>
              </div>
            </button>
            <v-dialog v-model="people.dialog" max-width="850px">
              <v-card class="modal px-sm-16 px-10 py-16" rounded="0">
                <v-btn
                  text
                  v-ripple="false"
                  @click="people.dialog = false"
                  class="close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="text-center mt-6 mb-14">
                  <v-avatar :size="$vuetify.breakpoint.xs ? 170 : 218">
                    <img
                      :src="require(`@/assets/img/${people.src}`)"
                      :alt="people.alt"
                    />
                  </v-avatar>
                  <div class="people-name mt-2">{{ people.name }}</div>
                  <div class="section-text">
                    {{ people.dialog_graduate }}
                  </div>
                </div>
                <div class="section-text" v-html="people.dialog_text"></div>
              </v-card>
            </v-dialog>
          </v-container>
        </VueSlickCarousel>
      </v-container>
    </section>

    <!--Section 5 - OUR SERVICES SECTION-->
    <section id="our-services" class="pt-16">
      <v-container
        id="our-services-container"
        fluid
        class="text-center px-10 px-sm-16"
      >
        <v-row
          class="px-sm-6 px-md-12"
          dense
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-once="true"
          ><v-col cols="12">
            <h5 class="section-tag">Our Services</h5>
            <h1 class="section-title">Experience a better pharmacy.</h1>
          </v-col></v-row
        >
        <v-row class="px-sm-6 px-md-12 pt-6" dense>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="mb-10 mb-md-0 mb-lg-8"
            v-for="service in services"
            :key="service.title"
          >
            <div class="service-container">
              <v-card
                class="mx-auto text-center service-card"
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'secondaryColor'
                    : 'transparent'
                "
                height="100%"
                max-width="380"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
                data-aos-once="true"
              >
                <v-img
                  class="mx-auto"
                  :src="require(`@/assets/img/${service.svg_name}`)"
                  alt="icon"
                  max-width="50"
                  max-height="50"
                  contain
                ></v-img>

                <v-card-title class="primaryText--text justify-center">
                  {{ service.title }}
                </v-card-title>
                <v-card-text class="primaryText--text hidden-md-and-up">
                  {{ service.text }}<br /><br />
                  <v-icon left size="20px" class="primaryText--text">
                    far fa-clock </v-icon
                  ><b>Duration:</b> {{ service.duration }} Minutes
                </v-card-text>
                <v-btn
                  @click.stop="service.dialog = true"
                  text
                  class="btn-custom mt-2 mb-5 hidden-md-and-up"
                >
                  BOOK NOW
                </v-btn>
                <v-dialog v-model="service.dialog" width="80%">
                  <v-card class="modal" rounded="0" height="90vh">
                    <v-btn
                      text
                      v-ripple="false"
                      @click="service.dialog = false"
                      class="service-close transparent"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <iframe
                      :src="
                        'https://calendly.com/medwellpharmacy/' +
                        service.calendly_event_link
                      "
                      frameborder="0"
                      style="height: 100%; width: 100%"
                    ></iframe>
                    <!-- +'?hide_event_type_details=1' -->
                  </v-card>
                </v-dialog>
              </v-card>
              <v-card
                class="mx-auto secondaryColor service-hover hidden-sm-and-down"
                max-width="380"
              >
                <v-card-title class="primaryText--text justify-center">
                  {{ service.title }}
                </v-card-title>
                <v-card-text class="primaryText--text">
                  {{ service.text }}<br /><br />
                  <v-icon left size="20px" class="primaryText--text">
                    far fa-clock </v-icon
                  ><b>Duration:</b> {{ service.duration }} Minutes
                </v-card-text>
                <v-btn
                  @click.stop="service.dialog = true"
                  text
                  class="btn-custom mt-3 mb-5"
                >
                  BOOK NOW
                </v-btn>
                <v-dialog v-model="service.dialog" width="80%">
                  <v-card class="modal" rounded="0" height="90vh">
                    <v-btn
                      text
                      v-ripple="false"
                      @click="service.dialog = false"
                      class="service-close transparent"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <iframe
                      :src="
                        'https://calendly.com/medwellpharmacy/' +
                        service.calendly_event_link
                      "
                      frameborder="0"
                      style="height: 100%; width: 100%"
                    ></iframe>
                    <!-- +'?hide_event_type_details=1' -->
                  </v-card>
                </v-dialog>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <div id="our-services-background">
          <OurServicesBackground />
        </div>
      </v-container>
    </section>

    <!--Section 6 - WE'RE MAKING WAVES SECTION-->
    <section id="making-waves">
      <v-container
        fluid
        class="text-center px-5 px-sm-16"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-once="true"
      >
        <v-row class="px-sm-6 px-md-12" dense
          ><v-col cols="12">
            <h5 class="section-tag">We're Making Waves</h5>
          </v-col></v-row
        >
        <VueSlickCarousel v-bind="settingsMakingWaves">
          <v-container
            class="making-waves-content px-0 px-md-7"
            v-for="care in cares"
            :key="care.id"
          >
            <div class="testimonial-text">“{{ care.text }}”</div>
            <div class="patient mt-sm-4">{{ care.patient }}</div>
            <p class="section-tag text-capitalize mt-2">
              Care provided by <br />{{ care.care_provider }}
            </p>
          </v-container>
        </VueSlickCarousel>
      </v-container>
      <div id="making-waves-background">
        <MakingWavesBackground />
      </div>
    </section>

    <!--Section 7 - THE MEDWELL BLOG SECTION-->
    <section id="blog-block" class="medwell-blog">
      <v-container fluid class="text-center px-0 px-md-16">
        <v-row
          class="px-sm-6 px-md-12"
          dense
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-once="true"
          ><v-col cols="12">
            <h5 class="section-tag">The Medwell Blog</h5>
            <h1 class="section-title">Hello, Dear.</h1>
          </v-col></v-row
        >

        <v-container fluid class="px-0 px-md-9 px-lg-3 py-0">
          <VueSlickCarousel v-bind="settingsMedwellBlog">
            <v-container
              v-for="blog in blogs"
              :key="blog.id"
              class="slider px-0 px-md-14 px-lg-8"
              ><div class="card-wrap">
                <v-card
                  :href="'/our-blog/' + blog.id + '/' + getTitle(blog.title)"
                  :ripple="false"
                  class="mx-auto text-left secondaryColor"
                  max-width="500"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-once="true"
                >
                  <v-img
                    class="mx-auto"
                    :src="require(`@/assets/img/blog/${blog.img_name}`)"
                    :alt="blog.img_alt"
                    max-width="500"
                    max-height="500"
                    aspect-ratio="1"
                  >
                  </v-img>
                  <div class="card-date">
                    <div class="card-date-day">{{ getDate(blog.date) }}</div>
                    <div class="card-date-month-year text-uppercase">
                      {{ getMonth(blog.date) }} {{ getYear(blog.date) }}
                    </div>
                  </div>
                  <v-card-title class="primaryText--text">{{
                    blog.title
                  }}</v-card-title>
                  <v-card-text class="primaryText--text" v-html="blog.content">
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      class="hoverColor--text read-more"
                      :href="
                        '/our-blog/' + blog.id + '/' + getTitle(blog.title)
                      "
                      v-ripple="false"
                    >
                      Read More<v-icon right>fas fa-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-container>
          </VueSlickCarousel>
          <v-row dense class="mt-11">
            <v-col>
              <v-btn text href="/our-blog" class="btn-custom">
                View More
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </section>
  </div>
</template>


<script>
import $ from "jquery";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Hero1Background from "@/components/animatedSVG/Page1/Hero1Background";
import OurPracticeBackground from "@/components/animatedSVG/Page1/OurPracticeBackground";
import OurServicesBackground from "@/components/animatedSVG/Page1/OurServicesBackground";
import MakingWavesBackground from "@/components/animatedSVG/Page1/MakingWavesBackground";
import IconKKMRegistered from "@/components/animatedSVG/Page1/IconKKMRegistered.vue";
import IconHighlyQualifiedTeam from "@/components/animatedSVG/Page1/IconHighlyQualifiedTeam.vue";
import IconGenuineMedications from "@/components/animatedSVG/Page1/IconGenuineMedications.vue";
import IconMedicationSafety from "@/components/animatedSVG/Page1/IconMedicationSafety.vue";
import IconHassleFreeExperience from "@/components/animatedSVG/Page1/IconHassleFreeExperience.vue";
import IconSafeSecure from "@/components/animatedSVG/Page1/IconSafeSecure.vue";

export default {
  components: {
    VueSlickCarousel,
    Hero1Background,
    OurPracticeBackground,
    OurServicesBackground,
    MakingWavesBackground,
  },
  data() {
    return {
      dialog: false,
      icons: [
        {
          title: "KKM Registered",
          componentName: IconKKMRegistered,
          animation: "kkm-animation",
        },
        {
          title: "Highly Qualified Team",
          componentName: IconHighlyQualifiedTeam,
          animation: "highly-qualified-team-animation",
        },
        {
          title: "100% Genuine Medications",
          componentName: IconGenuineMedications,
          animation: "genuine-medications-animation",
        },
        {
          title: "Medication Safety",
          componentName: IconMedicationSafety,
          animation: "medication-safety-animation",
        },
        {
          title: "Hassle-Free Experience",
          componentName: IconHassleFreeExperience,
          animation: "hassle-free-experience-animation",
        },
        {
          title: "Safe & Secure",
          componentName: IconSafeSecure,
          animation: "safe-animation",
        },
      ],
      settingsTeam: {
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      team: [
        {
          dialog: false,
          src: "DrKevinOng.png",
          alt: "Dr. Kevin Ong",
          name: "Dr. Kevin Ong",
          job: "Pharmacist",
          dialog_graduate: "(Doctor of Pharmacy, BSc. In Biochemistry, USA)",
          dialog_text:
            "<p>Dr. Kevin Ong is a Doctor of Pharmacy (PharmD) graduate from the Massachusetts College of Pharmacy and Health Sciences (MCPHS University) at Worcester, MA, USA. He was also awarded a graduate certificate in Medication Safety upon his graduation from pharmacy school. </p>" +
            "<p>Dr. Ong was previously featured on <a href='https://mobile.swiperxapp.com/pharmacy-leaders-malaysia-kevin-ong/' target='_blank'>“Pharmacy Leaders of Malaysia”</a> in recognition for his volunteer work with the United Nations High Commissioner for Refugees (UNHCR) as a Diabetes Educator for healthcare professionals. </p>" +
            "<p>With years of both clinical and retail experience, Dr. Ong serves as Medwell’s Chief-Pharmacist. </p>",
        },
        {
          dialog: false,
          src: "DrSharonLee.png",
          alt: "Dr. Sharon Lee",
          name: "Dr. Sharon Lee",
          job: "Pharmacist",
          dialog_graduate: "(Doctor of Pharmacy, USA)",
          dialog_text:
            "<p>Dr. Sharon Lee is a Doctor of Pharmacy graduate from the Massachusetts College of Pharmacy and Health Sciences (MCPHS University) at Boston, MA, USA and is a licensed pharmacist in the United States. </p>" +
            "<p>During her time in the USA, she had hands-on experience with various aspects of pharmacy practice, including pharmaceutical compounding, women’s health, and dermatology. She strives to incorporate her experiences from the USA into her clinical practices at Medwell. </p>" +
            "Dr. Lee is also currently pursuing her Masters of Science in Clinical Research from MCPHS University. </p>",
        },
      ],
      services: [
        {
          dialog: false,
          svg_name: "IconMedicationTherapyManagement.svg",
          title: "Medication Therapy Management",
          text: "Medication Therapy Management (MTM) services ensure optimal benefits from your medication therapy, including drug-drug/drug-supplement interaction checks and fall prevention counselling.",
          duration: "60",
          calendly_event_link: "medicationtherapymangament",
        },
        {
          dialog: false,
          svg_name: "IconBloodSugarControl.svg",
          title: "Blood Sugar Control",
          text: "Keep your blood sugar in check. Both our pharmacists and dietitian work together to help get you on track with achieving optimum blood sugar control.",
          duration: "60",
          calendly_event_link: "bloodsugarcontrol",
        },
        {
          dialog: false,
          svg_name: "IconDietConsultation.svg",
          title: "Diet Consultation",
          text: "Personalized diet consultation catered to your unique health needs—weight loss/gain program, blood sugar management, heart health focus, etc.",
          duration: "30",
          calendly_event_link: "medicalnutritiontherapy",
        },
        {
          dialog: false,
          svg_name: "IconWomensHealth.svg",
          title: "Women's Health",
          text: "Our pharmacists provide targeted women’s health consultation services, including birth control initiation.",
          duration: "30",
          calendly_event_link: "womens-health",
        },
        {
          dialog: false,
          svg_name: "IconSkinConsultation.svg",
          title: "Skin Consultation",
          text: "Get clinical advice for your skin concerns coupled with our broad range of non-prescription and prescription strength skincare.",
          duration: "30",
          calendly_event_link: "skin-consultation",
        },
        {
          dialog: false,
          svg_name: "IconPre-&Post-NatalCare.svg",
          title: "Pre & Post-Natal Care",
          text: "We provide clinical advice and recommendations for all stages of parenthood from pregnancy and lactation to pediatric care coupled with a broad range of carefully curated products of safe and trusted brands from around the world.",
          duration: "30",
          calendly_event_link: "pre-and-post-natal-care",
        },
      ],
      settingsMakingWaves: {
        arrows: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        // fadeSpeed: 1000,
        // swipeToSlide: true,
        // pauseOnHover: false,
      },
      cares: [
        {
          id: 1,
          text: "I had a rash on my stomach area for weeks and it kept getting worse no matter what I tried. The pharmacist had a look at it and gave me some creams to apply and within a day the rash was gone!",
          patient: "JJ",
          care_provider: "Medwell Pharmacy",
        },
        {
          id: 2,
          text: "I recommend Medwell’s Smart Dosing System to everyone, especially the elderly! We no longer have to go through the trouble to help my mother with her medicines. It’s all sorted out by Medwell!",
          patient: "KL",
          care_provider: "Medwell Pharmacy",
        },
        {
          id: 3,
          text: "Medwell pharmacist was patient in explaining my birth control pill options, highly recommend!",
          patient: "JM",
          care_provider: "Medwell Pharmacy",
        },
        {
          id: 4,
          text: "Thanks to Dr. Lee’s advice, my engorgement was resolved before it got a lot worse and my breastfeeding journey is such a breeze now!",
          patient: "ZC",
          care_provider: "Dr. Sharon Lee (Pharmacist)",
        },
        {
          id: 5,
          text: "My blood sugar is now controlled thanks to Dr. Ong at Medwell!",
          patient: "ES",
          care_provider: "Dr. Kevin Ong (Pharmacist)",
        },
        {
          id: 6,
          text: "The Medication Therapy Management service helped me understand my medicines and how to take them.",
          patient: "JT",
          care_provider: "Medwell Pharmacy",
        },
        {
          id: 7,
          text: "Medwell is always one text away. I can easily get everything I need delivered right away from Medwell!",
          patient: "ST",
          care_provider: "Medwell Pharmacy",
        },
      ],
      settingsMedwellBlog: {
        arrows: false,
        slidesToShow: 3,
        speed: 1000,
        draggable: false,
        responsive: [
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 5000,
            },
          },
          {
            breakpoint: 959,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 5000,
            },
          },
        ],
      },
      blogs: [
        {
          id: 1,
          img_name: "Antimicrobial Resistance.jpg",
          img_alt: "Antimicrobial Resistance: The Role of Healthcare Providers",
          title: "Antimicrobial Resistance: The Role of Healthcare Providers",
          author: "Dr. Kevin Ong, B.Sc., Pharm.D.",
          date: "2019-11-27",
          content:
            "<p>The World Health Organization (WHO) considers antibiotic resistance to be one of the major public health threats of this century.<sup>1</sup> Antibiotic resistance occurs when potentially life-threatening bacteria resists the action of the agent that is administered to fight against them. Resistance of these agents can be developed through a variety of mechanisms such as drug inactivation, alteration of binding sites or metabolic pathways, or reduced drug accumulation by the bacteria.<sup>2</sup> This can make the treatment of bacterial infections such as pneumonia or endocarditis very challenging, causing treatment delays and leaving providers with limited choices of antibiotics. Practices such as inappropriate antibiotic prescribing or use, patients failing to take antibiotics in accordance to proper directions of use, incomplete antibiotic courses or improper antibiotic disposal can all contribute to the emergence of drug-resistant bacteria. </p>" +
            "<p>In Malaysia, antibiotics are often inappropriately prescribed for self-limiting conditions such as the common cold, which poses a significant threat to the development of antibiotic resistance. The National Medical Care Survey (NMCS) 2014 revealed higher antibiotic prescribing rates in the private sectors compared to the public settings.<sup>3</sup> Several reasons such as patient perception and expectation, as well as financial incentives for clinics and pharmacies could also contribute to the widespread of antibiotic use. Beyond the prescribing step, it is also important to consider the other stages of the medication use process. Patients themselves are also potential contributors to the preventable evolution of resistant bugs, also known as “Superbug”, by not taking the antibiotics as directed, which may include missed doses, and incomplete courses of antibiotic use. This practice will eventually lead to the survival of the bacteria, and allow them to thrive and evolve into resistant strains of bacteria that in worst cases may be untreatable by antibiotics currently available on the market.</p>" +
            "<p>As healthcare providers, we want to provide the best care possible to patients through treatment regimens that are both safe and effective. Patient education also plays an essential part in clinical practice as most individuals in this country possess poor health literacy. Since the majority of patients are deficient in basic scientific knowledge to sufficiently grasp the mechanisms of bacterial infection and the severity of antibiotic resistance development, healthcare providers here would need to be able to translate those information into layman terms that most patients can comprehend. In addition to that, ensuring that the patients understand the proper use of the prescribed antibiotics is essential, and this can be done through counseling patients on taking the prescribed antibiotic as directed and educating them on the necessity to complete the entire course. Lastly, patients should also be counselled on the proper disposal of unused antibiotics as most prescriptions are dispensed by the unit box, thus some patients prescribed with shorter antibiotic courses would be left with extra antibiotics. Proper disposal of antibiotics should be strongly encouraged as studies have shown that inappropriately discarded antibiotics can accumulate in the soil and water supplies.<sup>4</sup></p>",
          references: [
            {
              text: "World Health Organization. Antimicrobial resistance: global report on surveillance 2014. Geneva: World Health Organization; 2014.",
            },
            {
              text: "Aslam B, Wang W, Arshad M et al. Antibiotic resistance: a rundown of a global crisis. <i>Infect Drug Resist</i>. 2018;Volume 11:1645-1658. doi:10.2147/idr.s173867",
            },
            {
              text: "Ab Rahman N, Teng C, Sivasampu S. Antibiotic prescribing in public and private practice: a cross-sectional study in primary care clinics in Malaysia. <i>BMC Infect Dis</i>. 2016;16(1). doi:10.1186/s12879-016-1530-2",
            },
            {
              text: "Larsson D. Antibiotics in the environment. <i>Ups J Med Sci</i>. 2014;119(2):108-112. doi:10.3109/03009734.2014.896438",
            },
          ],
        },
        {
          id: 2,
          img_name: "Medication Safety.jpg",
          img_alt: "Medication Safety",
          title: "Medication Safety: Are We Practicing It?",
          author: "Dr. Sharon Lee, PharmD",
          date: "2020-01-02",
          content:
            "<p>Medications are used to treat, manage, and sometimes to prevent diseases. The safe use of medication is vital as the majority of medications have the capabilities to cause adverse events that may or may not be related to toxicity when used inappropriately. Medication safety is a term used to define the freedom from preventable harm with medication use.<sup>1</sup> Issues with the safe use of medications can negatively impact health outcomes, healthcare resources, length of stay at healthcare institutions, and the overall cost of healthcare.<sup>1</sup></p>" +
            "<p>The Medication Error Reporting System (MERS) is a national reporting system introduced by the Pharmaceutical Services Program, Ministry of Health Malaysia since 2009.<sup>2</sup> All healthcare professionals, including both government and private healthcare facilities are encouraged to report medication errors.<sup>2</sup> A number of hospitals picked up the practice of medication error reporting, but similar practices are not commonly observed in the community settings. Moreover, even though such practice can be seen in hospitals, it is however still not well enforced. True statistics of error reporting cannot be obtained mainly due to the “reporting culture” in Malaysia. Many healthcare workers fear the negative consequences of self-reporting an error that has been made, which then leads to the repetition of many preventable errors as staff training or retraining cannot be developed without true statistics of these medication error. To combat this issue, healthcare providers should be encouraged and reassured by their institutions that medication error reporting improves the public health, and that negative consequences should not be expected when reports are made voluntarily. Regulations could also be developed to protect the rights of healthcare workers when it comes to medication error reporting, which could greatly improve the overall rates of reporting.</p>" +
            "<p>A 4-year retrospective review of medication errors reported to MERS from 2009 to 2012 found that there was a low contribution to reporting from private hospitals and the majority of errors were detected and reported by pharmacists, which totals to 92.1% of the reports.<sup>3</sup> Another contributory factor to the underreporting of medication error could be attributed to the busy and hectic work environment as mentioned in a study on the acceptability of smartphone application for reporting among health care professionals in Malaysia.<sup>4</sup> From these studies, it is evident that national efforts were put into the reporting system to gather the statistics of medication errors on a national level. This gathered statistic can greatly benefit the public health through national healthcare interventions in an effort to reduce preventable medication safety issues. Furthermore, pharmacists seem to be the most active medication error reporters amongst all healthcare professionals. This is expected as pharmacists are the “drug experts” and are essentially the last line of defense for the patients in terms of medication use safety.</p>" +
            "<p>It is important for all to realize that medication safety problems do not only happen in hospital settings as such issues are also commonly seen in the community settings. Community pharmacists should be encouraged to practice medication error reporting through MERS given the vast amount of medication dispensing on the daily. Many may think that the lack of dispensing separation defeats the need for community pharmacists to run drug utilization reviews (DUR) or medication reconciliations as many believe that the doctors know best. While the doctors are respected for their expertise in the medicine field, it is undeniable that mistakes happen due to the great number of patients they see each day. Thus, community pharmacists should pick up the responsibility to double check the appropriate uses of the medications prescribed in order to ensure medication safety and optimal drug therapy.</p>",
          references: [
            {
              text: "Medication Safety. Patientsafetyinstitute.ca. <a href='https://www.patientsafetyinstitute.ca/en/Topic/Pages/Medication-Safety.aspx' target='_blank'>https://www.patientsafetyinstitute.ca/en/Topic/Pages/Medication-Safety.aspx</a>. Accessed December 30, 2019",
            },
            {
              text: "Medication Error (ME) Reporting. Pharmaceutical Services Programme. <a href='https://www.pharmacy.gov.my/v2/en/documents/medication-error-me-reporting.html-0' target='_blank'>https://www.pharmacy.gov.my/v2/en/documents/medication-error-me-reporting.html-0</a>. Published 2019. Accessed December 30, 2019.",
            },
            {
              text: "Samsiah A, Othman N, Jamshed S, Hassali M, Wan-Mohaina W. Medication errors reported to the National Medication Error Reporting System in Malaysia: a 4-year retrospective review (2009 to 2012). <i>Eur J Clin Pharmacol</i>. 2016;72(12):1515-1524. doi:10.1007/s00228-016-2126-x",
            },
            {
              text: "George D, HSS A, Hassali A. Medication Error Reporting: Underreporting and Acceptability of Smartphone Application for Reporting among Health Care Professionals in Perak, Malaysia. <i>Cureus</i>. 2018. doi:10.7759/cureus.2746",
            },
          ],
        },
        {
          id: 3,
          img_name: "Childhood Obesity.jpg",
          img_alt: "Childhood Obesity",
          title: "Childhood Obesity a Growing Concern in Malaysia",
          author: "Dr. Sharon Lee, PharmD",
          date: "2021-09-01",
          content:
            "<p>Obesity has been a growing modern age concern on a global scale for the past few decades. With the modernization of societies, daily living conveniences have not only led to poor diet, but also contributed to sedentary lifestyles. According to the World Health Organization (WHO), Malaysia has the highest rate of obesity and overweight statistics among the Asian countries with 64% of male and 65% of female population being either overweight or obese.<sup>1</sup> This statistics also extends to the children of this country where a child and adolescent study in Asia done in 2013 revealed that Malaysia is one of the top three countries with a high prevalence of obese children between ages 6 months to 12 years.<sup>2</sup> Furthermore, the National Health and Morbidity Survey done in 2015 also revealed that 11.8% of children below 18 years old were found to be obese in Malaysia.<sup>3</sup></p>" +
            "<p>Childhood obesity is a disease rather than a condition. WHO noted that childhood obesity is associated with a higher risk of premature death and disability in adulthood as these children have a much higher risk of developing non-communicable chronic diseases such as type II diabetes and cardiovascular diseases, most notably hypertension and dyslipidemia.<sup>4</sup> In addition to that, obesity is also one of the main causes for obstructive sleep apnea (OSA) and hypoventilation syndrome in children. The most prominent signs and symptoms of OSA include loud snoring and episodes of interrupted breathing during sleep due to complete or partial obstruction of the upper airways. In obese individuals, the airways become narrower due to the increased fat deposit around the neck, and this leads to shallow breathing during sleep, also known as hypoventilation, which is further complicated by increased fat around the abdomen and chest area in obese patients. OSA and hypoventilation are sleep disorders that can cause a decrease in oxygen and an increase in carbon dioxide during an obese child’s sleep, and long-term lack of oxygen in the blood can result in early deterioration of memory, which can greatly affect the normal growth of a child.</p>" +
            "<p>Malaysia is known to the world as the food heaven and we take pride in our local cuisine that is full of culture and flavor. As such, the Malaysian diet mainly consists of unhealthy carbohydrates such as the all-time favorite Nasi Lemak, Mee Goreng, Nasi Goreng, Chicken Rice, and all kinds of sugary drinks. While the foods taste heavenly, the nutrition value of these foods are lacking. Adding to the problem is the sedentary lifestyle that most Malaysians live by, and together with poor diet, problems like obesity, hypertension and diabetes continue to be on the rise. </p>" +
            "<p>In the recent years, there seems to be an increase public awareness of chronic diseases such as hypertension and diabetes. While most people understand the risks that these chronic diseases bring upon our health, many ignore the fact that overweightness or obesity is also a major contributing factor to poor health. Today, most people in Malaysia take obesity lightly and often think that it is merely a phase in one’s childhood or even adulthood. Many continue to refuse a change to their diet and lack the motivation to improve their sedentary lifestyle. Although pharmacists are not as well trained in these areas like a dietician or nutritionist, we are still the frontlines of patient contact in the community. Raising awareness about the complications of chronic overweightness and obesity can begin at the pharmacy setting. As a pharmacist, we have the skills to educate patients on the basic concept of calorie-counting and the importance of physical activities when it comes to dieting, and the referral to dietitian or nutritionist is always an option.</p>",
          references: [
            {
              text: "Kaur N. Malaysia and WHO call for more investment in primary health care the 21st century. Who.int. <a href='https://www.who.int/malaysia/news/detail/08-04-2019-malaysia-and-who-call-for-more-investment-in-primary-health-care-the-21st-century' target='_blank'>https://www.who.int/malaysia/news/detail/08-04-2019-malaysia-and-who-call-for-more-investment-in-primary-health-care-the-21st-century</a>. Published 2019. Accessed December 15, 2019.",
            },
            {
              text: "Poh B, Ng B, Siti Haslinda M et al. Nutritional status and dietary intakes of children aged 6 months to 12 years: findings of the Nutrition Survey of Malaysian Children (SEANUTS Malaysia). <i>British Journal of Nutrition</i>. 2013;110(S3):S21-S35. doi:10.1017/s0007114513002092",
            },
            {
              text: "<i>National Health & Morbidity Survey 2015</i>. 2nd ed. Institute of Public Health, Ministry of Health Malaysia; 2019:78-91. <a href='http://iku.moh.gov.my/images/IKU/Document/REPORT/nhmsreport2015vol2.pdf' target='_blank'>http://iku.moh.gov.my/images/IKU/Document/REPORT/nhmsreport2015vol2.pdf</a>. Accessed December 15, 2019.",
            },
            {
              text: "Abdullah J, Nasri M. BERNAMA.com Health -. Health.bernama.com. <a href='http://health.bernama.com/news.php?id=1740885' target='_blank'>http://health.bernama.com/news.php?id=1740885</a>. Published 2019. Accessed December 15, 2019.",
            },
          ],
        },
      ],
    };
  },
  created() {
    let _this = this;
    _this.sortByDate();
  },
  methods: {
    sortByDate: function () {
      //sort the blogs by latest date
      return this.blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    getDate(prop) {
      var d = new Date(prop);
      return d.getDate();
    },
    getMonth(prop) {
      const month = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      var m = new Date(prop);
      return month[m.getMonth()];
    },
    getYear(prop) {
      var y = new Date(prop);
      return y.getFullYear();
    },
    getTitle(prop) {
      var title = prop.replace(/\s/g, "-").toLowerCase();
      return title;
    },
  },
  mounted() {
    var flagscroll1 = true;
    var flagscroll2 = true;

    //Line animation on scroll
    $(window).on("scroll", function () {
      var top1 = $(window).scrollTop() + $(window).height(),
        isVisible1 = top1 > $("#our-practice-background").offset().top;
      if (flagscroll1 == true && isVisible1) {
        flagscroll1 = false;
        $("#our-practice-background").toggleClass(
          "our-practice-animation",
          isVisible1
        );
      }

      var top2 = $(window).scrollTop() + $(window).height(),
        isVisible2 = top2 > $("#our-services-background").offset().top;
      if (flagscroll2 == true && isVisible2) {
        flagscroll2 = false;
        $("#our-services-background").toggleClass(
          "our-services-animation",
          isVisible2
        );
      }

      $("#icon-list .img-icon").each(function () {
        var flagscroll3 = true;
        var top3 = $(window).scrollTop() + $(window).height(),
          isVisible3 = top3 > $(this).offset().top;
        if (flagscroll3 == true && isVisible3) {
          flagscroll3 = false;
          $(this).toggleClass("animate", isVisible3);
        }
      });
    });
  },
};
</script>

<style scoped>
.hero #hero-1-background,
#our-practice #our-practice-background,
#our-services #our-services-background,
#making-waves #making-waves-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

/* ---- due to z-index issue, change #our-services's position absolute to static ----*/
/* make #our-services-background absolute to #our-services-container */
.pharmacy {
  position: relative;
  z-index: 0;
}

#our-services {
  position: static;
}

#our-services-container {
  position: relative;
}
/* -------- */

.hero #hero-1-background,
#making-waves #making-waves-background {
  align-items: center;
}

#our-practice #our-practice-background,
#our-services #our-services-background {
  align-items: flex-start;
}

.hero #text-book-consultation {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.modal {
  position: relative;
}

.modal > .section-text >>> a {
  color: #a56c6a;
  text-decoration: none;
}

.modal > .section-text >>> a:hover {
  text-decoration: underline;
}

.close {
  position: absolute;
  top: 20px;
  right: 0px;
  background-color: #ffffff;
  color: #2b2b2b;
  z-index: 300;
}

#our-team {
  margin-bottom: 66px;
}

.people-name {
  font-family: "BigCaslon-Rom";
  font-size: 35px;
}

#our-team .slider {
  width: 80%;
}

@media (max-width: 800px) {
  .people-name {
    font-size: 27px;
  }

  #our-team .slider {
    width: 100%;
    padding: 0px;
  }
}

#our-team .slider-item:hover > .v-avatar {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

#our-team .slider-item:hover > .slider-text > * {
  color: #a56c6a;
}

#our-team .slider-item > .slider-text {
  position: relative;
  top: 0px;
}

#our-team .slider-item:hover > .slider-text {
  top: 20px;
}

#icon-list .img-icon {
  margin-top: 16px;
  height: 50px;
}

#icon-list .img-icon svg {
  max-height: 50px;
  max-width: 50px;
  object-fit: contain;
}

#our-services .v-image {
  margin-top: 16px;
  height: 80px;
}

#icon-list .v-card__title,
#our-services .v-card__title {
  word-break: break-word;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

#our-services .v-card__text {
  font-family: "Open Sans", sans-serif;
  font-size: 19px;
  line-height: 30px;
}

#icon-list .v-sheet.v-card,
#our-services .v-sheet.v-card {
  padding: 20px 16px;
}

#our-services .v-sheet.v-card {
  border-radius: 30px;
}

#our-services .v-sheet.v-card.transparent {
  box-shadow: none;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
}

#our-services .v-sheet.v-card .v-image {
  border-radius: 0;
}

#our-services .v-overlay .v-sheet.v-card {
  opacity: 0.9;
  z-index: 0;
}

#our-services .service-container {
  position: relative;
}

#our-services .service-hover {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  /* opacity: 0.9; */
  z-index: 1;
  visibility: hidden;
}

#our-services .service-container:hover .service-hover {
  visibility: visible;
}

.service-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #2b2b2b;
  z-index: 300;
}

#making-waves .testimonial-text {
  font-size: 40px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-family: "BigCaslon-Rom";
  color: #2b2b2b;
}

#making-waves .patient {
  font-family: "BigCaslon-Rom";
  font-size: 33px;
  line-height: 65px;
}

#making-waves .making-waves-content:hover {
  cursor: grab;
}

#making-waves .making-waves-content:active {
  cursor: grabbing;
}

@media (max-width: 1263px) {
  #our-services .v-overlay .v-sheet.v-card {
    padding: 5px 3px;
  }

  #icon-list .v-card__title,
  #our-services .v-card__title {
    font-size: 19px;
  }

  #our-services .v-card__text {
    font-size: 17px;
    line-height: 20px;
  }

  #making-waves .testimonial-text {
    font-size: 35px;
  }

  #making-waves .patient {
    font-size: 30px;
  }
}

@media (max-width: 959px) {
  #our-team {
    margin-bottom: 36px;
  }

  #icon-list .v-card__title,
  #our-services .v-card__title {
    font-size: 18px;
  }

  #our-services .v-card__text {
    font-size: 16px;
  }

  #making-waves .testimonial-text {
    font-size: 30px;
  }

  #making-waves .patient {
    font-size: 25px;
  }
}

@media (max-width: 599px) {
  #making-waves .testimonial-text {
    font-size: 27px;
  }

  #making-waves .patient {
    font-size: 23px;
  }
}

@media (max-width: 480px) {
  .service-close {
    right: -3px;
  }

  #making-waves .testimonial-text {
    font-size: 24px;
  }

  #making-waves .patient {
    font-size: 21px;
    line-height: 45px;
    margin-top: 10px;
  }
}

@media (max-width: 350px) {
  #making-waves .testimonial-text {
    display: block;
    line-height: 1;
    overflow-y: auto;
  }
}
</style>

<style>
#our-team .slick-track {
  margin: auto;
  padding: 10px 0;
}

#blog-block .slick-track {
  display: flex !important;
  margin: auto !important;
  padding: 30px 0;
}

#blog-block .slick-slide {
  height: inherit !important;
  display: flex;
  align-content: stretch;
  justify-content: center;
}

#blog-block .slider.container,
#blog-block .slider.container .card-wrap,
#blog-block .slider .v-card {
  height: 100%;
}

@media (max-width: 599px) {
  #blog-block .slick-slide {
    margin-left: 40px;
    margin-right: 40px;
  }
}
</style>