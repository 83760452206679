<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="34.6"
    height="39.401"
    viewBox="0 0 35.6 40.401"
  >
    <g
      id="Group_95"
      data-name="Group 95"
      transform="translate(-1041.633 -1360.756)"
    >
      <path
        id="Path_216"
        data-name="Path 216"
        d="M140.026,175.717l5.835,5.834L156.5,170.917"
        transform="translate(911.472 1203.375)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
      <path
        id="Path_217"
        data-name="Path 217"
        d="M48.8,38.4a.793.793,0,0,1-.237-.035C42.787,36.568,32,30.276,32,23.2V5.6a.8.8,0,0,1,.57-.766l16-4.8a.8.8,0,0,1,.459,0l16,4.8A.8.8,0,0,1,65.6,5.6V23.2c0,7.075-10.787,13.366-16.563,15.165A.793.793,0,0,1,48.8,38.4Z"
        transform="translate(1010.133 1361.257)"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.safe-animation.animate {
  stroke-dasharray: 120;
  stroke-dashoffset: 0;
  -webkit-animation: animate-safe ease-out 3s forwards;
  animation: animate-safe ease-out 3s forwards;
}

@-webkit-keyframes animate-safe {
  from {
    stroke-dashoffset: 120;
  }
}

@keyframes animate-safe {
  from {
    stroke-dashoffset: 120;
  }
}
</style>