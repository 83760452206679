<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1678.095"
    height="573.92"
    viewBox="0 0 1678.095 573.92"
  >
    <path
      id="Path_209"
      data-name="Path 209"
      d="M0,148.888s194.8,182.886,385.817,0,391.122,0,391.122,0,287.658,283.707,551.817,69.168S1603.528,0,1603.528,0"
      transform="translate(20.424 251.404) rotate(-8)"
      fill="none"
      stroke="#ced5cc"
      stroke-width="50"
      opacity="0.30"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  width: 100%;
  height: auto;
  transform: scale(1.3) translate(-5%, 8%);
}

@media (max-width: 1263px) {
  svg {
    transform: scale(1.5) translate(-6%, 10%);
  }
}

@media (max-width: 959px) {
  svg {
    transform: scale(1.9) translate(-6%, 38%);
  }
}

@media (max-width: 767.98px) {
  svg {
    transform: scale(2.6) translate(-6%, 36%);
  }
}

@media (max-width: 599px) {
  svg {
    transform: scale(2.9) translate(-6%, 36%);
  }
}

@media (max-width: 350px) {
  svg {
    transform: scale(3.2) translate(-6%, 36%);
  }
}

/* Animation */
#Path_209 {
  stroke-dasharray: 1824;
  stroke-dashoffset: 0;
  -webkit-animation: animate-Path_209 4s ease-in forwards;
  animation: animate-Path_209 4s ease-in forwards;
}

@-webkit-keyframes animate-Path_209 {
  from {
    stroke-dashoffset: 1824;
  }
}

@keyframes animate-Path_209 {
  from {
    stroke-dashoffset: 1824;
  }
}
</style>

<style>
</style>