<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width="100%"
    height="714.46"
    viewBox="0 0 1366.755 714.46"
  >
    <path
      id="Path_155"
      data-name="Path 155"
      d="M-9.6,4226.044s322.289-102.926,562-42.886c124.3,21.443,210.441,43.807,337.375,42.886,105.6.921,346.253-2.145,467.022-34.308.8,163.747,0,458.88,0,458.88s-413.238,216.494-697.6,86.8S-9.6,4766.409-9.6,4766.409Z"
      transform="translate(9.6 -4164.353)"
      preserveAspectRatio="none"
      fill="#CED5CC"
      opacity="0.30"
    />
    <animate
      xlink:href="#Path_155"
      repeatCount="indefinite"
      attributeName="d"
      attributeType="XML"
      values="M-9.6,4172.219s352,161.829,564.8,69.9c89.126-44.253,213.067-68.95,340-69.9,105.6.954,345.229,57.475,461.6,122.09.8,169.494,0,474,0,474s-393.2-89.77-697.6-10.771S-10,4729.885-10,4729.885Z;
                            M-9.6,4226.044s322.289-102.926,562-42.886c124.3,21.443,210.441,43.807,337.375,42.886,105.6.921,346.253-2.145,467.022-34.308.8,163.747,0,458.88,0,458.88s-413.238,216.494-697.6,86.8S-9.6,4766.409-9.6,4766.409Z;
                            M-9.6,4172.219s352,161.829,564.8,69.9c89.126-44.253,213.067-68.95,340-69.9,105.6.954,345.229,57.475,461.6,122.09.8,169.494,0,474,0,474s-393.2-89.77-697.6-10.771S-10,4729.885-10,4729.885Z"
      dur="18s"
      fill="freeze"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  transform: scaleY(1.1) translateY(5%);
}

@media (max-width: 599px) {
  svg {
    transform: scaleY(1) translateY(4%);
  }
}
</style>