<template>
  <div>
    <!--Section Banner-->
    <section class="hero d-flex flex-column justify-center">
      <v-container
        fluid
        class="px-sm-16 px-10 text-center"
        data-aos="fade-down"
        data-aos-duration="2000"
        data-aos-once="true"
      >
        <v-row justify="space-around" dense no-gutters>
          <v-col cols="12">
            <h1 class="hero-title">
              The Smarter, <br class="d-none d-sm-flex" />
              Simpler Pharmacy.
            </h1>
          </v-col>
        </v-row>
        <v-row justify="space-around" dense no-gutters>
          <v-col sm="12" cols="9">
            <h2 class="section-subtitle">
              We are a new kind of pharmacy, designed to
              <br class="d-none d-sm-flex" />
              help you lead a better life.
            </h2>
          </v-col>
        </v-row>
      </v-container>
      <div id="hero-2-background"><Hero2Background /></div>
    </section>

    <!--Section Our Practice-->
    <section id="our-practice-2">
      <v-container fluid class="px-sm-16 px-10">
        <!--Show Only On MD and down-->
        <v-row dense class="px-md-12 px-sm-6 hidden-lg-and-up">
          <v-col
            cols="12"
            class="text-center"
            data-aos="fade-down"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <h5 class="section-tag">Our Practice</h5>
            <h1 class="section-title">Setting New Standards in Pharmacy</h1>
          </v-col>
        </v-row>
        <!--End MD and down-->

        <v-row dense align="center" class="px-md-12 px-sm-6">
          <v-col
            lg="7"
            cols="12"
            class="pr-lg-14 text-lg-left text-center"
            :order="$vuetify.breakpoint.mdAndDown ? 2 : 1"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <!--Show Only On LG and up-->
            <div class="hidden-md-and-down">
              <h5 class="section-tag">Our Practice</h5>
              <h1 class="section-title">Setting New Standards in Pharmacy</h1>
            </div>
            <!--End LG and up-->

            <p class="section-text mt-lg-0 mt-6">
              We are an independent pharmacy with a mission to improve patient
              health outcomes by removing the complexity of managing medications
              through our specially designed personalized health concierge
              services with the help of modern technology.
            </p>
          </v-col>
          <v-col
            lg="5"
            cols="12"
            :order="$vuetify.breakpoint.mdAndDown ? 1 : 2"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <v-img
              src="../assets/img/OurPractice2.png"
              alt="OurPractice2"
              max-height="550"
              max-width="470"
              aspect-ratio="0.88"
              class="pic mx-auto"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!--Section Our People-->
    <section id="people">
      <v-container fluid class="px-sm-16 px-10">
        <v-row dense class="px-md-12 px-sm-6 text-center">
          <v-col
            cols="12"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <h5 class="section-tag">Our People</h5>
            <h1 class="section-title">Kevin & Sharon Care</h1>
          </v-col>
        </v-row>
        <v-row
          dense
          align="center"
          class="px-md-12 px-sm-6"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-once="true"
        >
          <v-col lg="5" cols="12">
            <v-img
              src="../assets/img/KevinSharonCare.jpg"
              alt="OurPeople"
              max-height="550"
              max-width="470"
              aspect-ratio="0.80"
              class="pic mx-auto"
            >
            </v-img>
          </v-col>
          <v-col lg="7" cols="12" class="pl-lg-14 text-lg-left text-center">
            <div class="section-text mt-lg-0 mt-6">
              <p>
                As US trained pharmacists, Kevin and Sharon understand the
                importance of all rounded healthcare to improve patient health
                outcomes and thus decided to bring their experiences from the US
                back home here in Malaysia.
              </p>
              <p>
                Realizing the missing link in interprofessional collaboration
                (IPC) in our country, Medwell was born as the first step towards
                normalizing IPC between different healthcare professionals in
                the community setting.
              </p>
              <p>
                Keeping patients’ best interests in mind, Kevin and Sharon
                strive to bring the best quality of care the local community
                with an emphasis on IPC.
              </p>
              <p>
                As new parents to a sweet baby boy, Kevin and Sharon developed
                new found passion for maternal and paediatric care. As a
                breastfeeding mother, Sharon also looks to further her knowledge
                in lactation.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div id="people-background">
        <PeopleBackground />
      </div>
    </section>

    <!--Section Our Location-->
    <section id="our-location">
      <v-container fluid class="px-sm-16 px-10">
        <v-row
          dense
          class="px-md-12 px-sm-6 text-center"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-once="true"
        >
          <v-col cols="12">
            <h5 class="section-tag">Our Location</h5>
            <h1 class="section-title">Designed with You In Mind</h1>
          </v-col>
        </v-row>
      </v-container>
      <!--Location Video-->
      <v-container
        fluid
        class="px-md-16 px-4"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-once="true"
      >
        <div class="px-md-11">
          <video width="100%" height="100%" controls muted>
            <source src="../assets/video/OurLocation.mp4" type="video/mp4" />
          </video>
        </div>
      </v-container>

      <!--Store Location-->
      <v-container fluid class="px-sm-16 px-10">
        <v-row dense no-gutters class="px-md-12 px-sm-6" justify="space-around">
          <v-col
            md="4"
            cols="12"
            class="mt-14 d-flex flex-column"
            v-for="location in locations"
            :key="location.shop_name"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-once="true"
          >
            <div class="text-md-left text-center">
              <h3 class="location-tag">
                {{ location.shop_name }}
              </h3>
              <div class="location-text mt-4">
                <div class="d-flex align-start justify-md-start justify-center">
                  <v-icon class="primaryText--text">
                    fas fa-map-marker-alt
                  </v-icon>
                  <div
                    class="d-inline-block"
                    v-html="location.shop_address"
                  ></div>
                </div>
                <a :href="`tel:${location.phone_1}`" class="d-block">
                  <v-icon class="primaryText--text"> fas fa-phone-alt </v-icon>
                  {{ location.phone_1 }}
                </a>
                <a
                  :href="`https://wa.me/${getPhone(location.phone_2)}`"
                  target="_blank"
                  class="d-block"
                >
                  <v-icon class="primaryText--text"> fab fa-whatsapp </v-icon>
                  {{ location.phone_2 }}
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
.hero #hero-2-background,
#people-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.hero #hero-2-background {
  align-items: center;
}

#people-background {
  align-items: flex-end;
}

#our-location .location-tag {
  font-family: "BigCaslon-Rom";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 30px;
}

#our-location .location-text {
  font-family: "Open Sans", sans-serif;
  font-size: 19px;
  font-weight: 400;
  color: #2b2b2b;
}

#our-location .location-text > * {
  margin-top: 6px;
}

#our-location .location-text a {
  text-decoration: none;
  color: #2b2b2b;
}

#our-location .location-text a:hover {
  color: #a56c6a;
}

#our-location .fa-map-marker-alt {
  font-size: 22px;
  margin-top: 4px;
  margin-right: 20px;
}

#our-location .fa-phone-alt {
  font-size: 22px;
}

#our-location .fa-whatsapp {
  font-size: 28px;
  margin-left: -2px;
}

#our-location .fa-phone-alt,
#our-location .fa-whatsapp {
  margin-bottom: 4px;
  margin-right: 10px;
}

/*MD*/
@media (max-width: 1263.98px) {
  #our-location .location-tag {
    font-size: 21px;
  }

  /* #our-location .location-text,
  #our-location .fa-map-marker-alt,
  #our-location .fa-phone-alt,
  #our-location .fa-whatsapp {
    font-size: 16px;
  } */
}

/* @media (max-width: 959.98px) {
  #our-location .location-tag {
    font-size: 25px;
  }

  #our-location .location-text,
  #our-location .fa-map-marker-alt,
  #our-location .fa-phone-alt,
  #our-location .fa-whatsapp {
    font-size: 18px;
  }
} */
</style>

<script>
import $ from "jquery";
import Hero2Background from "@/components/animatedSVG/Page2/Hero2Background";
import PeopleBackground from "@/components/animatedSVG/Page2/PeopleBackground";
export default {
  components: {
    Hero2Background,
    PeopleBackground,
  },
  data() {
    return {
      locations: [
        {
          shop_name: "Milan Square",
          shop_address:
            "Lot 13-14, Milan Square,<br /> Jalan Wan Alwi, <br /> Kuching 93350",
          phone_1: "082-265 695",
          phone_2: "011-28888 695",
        },
        {
          shop_name: "Matang Parade",
          shop_address:
            "Lot 25, Matang Parade,<br /> Jalan Matang,<br /> Kuching 93050",
          phone_1: "082-745 591",
          phone_2: "011-28888 695",
        },
        {
          shop_name: "Semariang Square",
          shop_address:
            "3, Semariang Square,<br /> Jalan Semariang,<br /> Kuching 93050",
          phone_1: "082-506 649",
          phone_2: "011-28888 695",
        },
      ],
    };
  },
  methods: {
    getPhone(prop) {
      var phone = prop;
      console.log(phone);
      var cleanPhoneFormat = "6".concat(phone.replace(/[- ]/g, ""));
      console.log(cleanPhoneFormat);
      return cleanPhoneFormat;
    },
  },
  mounted() {
    // $("nav .v-toolbar__content .v-toolbar__items a.story").addClass(
    //   "pageActive"
    // );
    var flagscroll = true;
    // Line animation on scroll
    $(window).on("scroll", function () {
      var top = $(window).scrollTop() + $(window).height(),
        isVisible = top > $("#people-background").offset().top;
      if (flagscroll == true && isVisible) {
        flagscroll = false;
        $("#people-background").toggleClass("people-animation", isVisible);
      }
    });

    //autoplay video when scroll to it
    var media = $("video").not("[autoplay='autoplay']");
    var tolerancePixel = 40;
    function checkMedia() {
      // Get current browser top and bottom
      var scrollTop = $(window).scrollTop() + tolerancePixel;
      var scrollBottom =
        $(window).scrollTop() + $(window).height() - tolerancePixel;
      media.each(function () {
        var yTopMedia = $(this).offset().top;
        var yBottomMedia = $(this).height() + yTopMedia;
        if (scrollTop < yBottomMedia && scrollBottom > yTopMedia) {
          $(this).get(0).play();
        } else {
          $(this).get(0).pause();
        }
      });
    }
    $(document).on("scroll", checkMedia);
  },
};
</script>
