<template>
  <section class="hero">
    <v-container
      fluid
      class="text-center hero3 px-10 px-sm-16"
      data-aos="fade-up"
      data-aos-duration="2000"
      data-aos-once="true"
    >
      <v-row class="px-0 px-md-12" justify="space-around" dense>
        <v-col cols="12"><h5 class="section-tag">FOR DOCTORS</h5></v-col>
        <v-col cols="12"><h1 class="hero-title">Let’s Work Together</h1></v-col>
        <v-col cols="12" lg="9">
          <p class="section-subtitle">
            Our belief in utilising interprofessional collaboration is one built
            on years of studies and research. By increasing accessibility of
            medical information and providing readily available consultations,
            patient outcomes can be vastly improved. Healthcare providers
            achieve more together than they can alone, benefiting from the
            ability to serve patients better.
          </p>
          <p class="section-subtitle">
            Times are changing, it is going to take more than a single
            practitioner to bridge the gap in healthcare infrastructure but we
            want to confront these issues together. Join us in our effort to
            improve the system for a better future.
          </p>
        </v-col>
      </v-row>

      <div class="contact-form-container mx-auto">
        <v-form ref="form">
          <v-text-field
            label="Name"
            v-model="name"
            :rules="textRules"
            filled
            required
          >
          </v-text-field>
          <v-text-field
            label="Clinic/Institution"
            v-model="clinic"
            :rules="textRules"
            filled
            required
          >
          </v-text-field>
          <v-text-field
            label="Specialty"
            v-model="specialty"
            :rules="textRules"
            filled
            required
          >
          </v-text-field>
          <v-text-field
            label="Contact Number"
            v-model="contact"
            :rules="contactRules"
            filled
            required
          >
          </v-text-field>
          <v-text-field
            label="Email Address"
            v-model="email"
            :rules="emailRules"
            filled
            required
          >
          </v-text-field>
          <div class="recaptcha-wrapper">
            <vue-recaptcha
              class="mt-0 mb-1 my-md-5 d-flex justify-center"
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpired"
              sitekey="6LdKH-UcAAAAAKxBXC3K2vR7_w7h5cyuZHTnXKUb"
            >
            </vue-recaptcha>

            <p class="recaptcha-error caption">
              {{ pleaseTickRecaptchaMessage }}
            </p>
          </div>
          <v-btn
            text
            class="btn-custom mt-5"
            @click="validate"
            :loading="loading"
            >SUBMIT</v-btn
          >
        </v-form>
      </div>
    </v-container>
    <div id="hero-3-background">
      <Hero3Background />
    </div>
    <div id="contact-form-background">
      <ContactFormBackground />
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import { VueRecaptcha } from "vue-recaptcha";
import Hero3Background from "@/components/animatedSVG/Page3/Hero3Background";
import ContactFormBackground from "@/components/animatedSVG/Page3/ContactFormBackground";

export default {
  components: {
    VueRecaptcha,
    Hero3Background,
    ContactFormBackground,
  },
  data() {
    return {
      name: "",
      textRules: [(v) => !!v || "Please fill out this field."],
      clinic: "",
      specialty: "",
      contact: "",
      email: "",
      contactRules: [
        (v) => !!v || "Please fill out this field.",
        (v) =>
          /^([0-9-]+)$/.test(v) ||
          "Please enter a valid contact number. e.g. 0127773333",
      ],
      emailRules: [
        (v) => !!v || "Please fill out this field.",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "Please enter a valid E-mail. e.g. email@example.com",
      ],
      pleaseTickRecaptchaMessage: "",
      recaptchaVerified: false,
      recaptchaExpired: false,
      loading: false,
    };
  },
  methods: {
    validate() {
      if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage = "Please tick recaptcha.";
      }
      if (
        this.$refs.form.validate() &&
        this.recaptchaVerified &&
        !this.recaptchaExpired
      ) {
        // this.$refs.recaptcha.execute();
        this.loading = true;
        console.log("filled form");
        // console.log(
        //   this.name,
        //   this.clinic,
        //   this.specialty,
        //   this.contact,
        //   this.email
        // );
        this.axios
          .post(
            "https://app.jinni.my/api/enquiry/605e1f16-a5ba-497b-862a-906bc3ec29b3",
            {
              name: this.name,
              clinic: this.clinic,
              speciality: this.specialty,
              contact_no: this.contact,
              email: this.email,
            },
            {
              headers: {
                Authorization:
                  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjZjZjc3NTQzZmE2MDJmM2M3ZDU0MmZhODJhM2Q1YjQxZWZmNzZlYWFhNzMyZTA3ODc1NjNlMmI0Y2EyNTZkYTkyYTY2Yjg3MzJhODFkMTFjIn0.eyJhdWQiOiI1IiwianRpIjoiNmNmNzc1NDNmYTYwMmYzYzdkNTQyZmE4MmEzZDViNDFlZmY3NmVhYWE3MzJlMDc4NzU2M2UyYjRjYTI1NmRhOTJhNjZiODczMmE4MWQxMWMiLCJpYXQiOjE2MzUwNzE0NDQsIm5iZiI6MTYzNTA3MTQ0NCwiZXhwIjo0NzkwNzQ1MDQ0LCJzdWIiOiIxNiIsInNjb3BlcyI6W119.sFzdGw6f-vZNrSztOF8a9h7F8awWdlEnbFSQ7J9I562Tt4iXN2qc0E4Hb-ppOh3YxnTfenZB6xf4VknnCvmP21RnoAlb9VARWj0NRbhy2aqbDecMY4jPgv-LWmXZHhYUJygji6u2AiLuCz7csDBY-eh2HFjPWGlXPnNGzAptdn2FYLJpnF5R9KiT1Rv6R8xkU-kYpkEH6JZqF6voVW3n4ueCA6HaS8z_y51VNsVOwsK_JhJFQ2BGcUHmENNvIIdZMFAGavj20EZT50gsK2qU47_0LKCYxz3paZeuP5gh-1tr_qsNIHUwpxCpNAl-dGQnqKKtRsr8MfVpHivJjTOO_Mdfa4zMuScev63AOQ3-pwjxk5kt3Uj1BnrSqK-u5OnYXBWH_Kvuc9UTPnicOMLzHloLnotSHEI_zF0UlRklKng6hWESYiS4nDl4WvqI44RCVUzKsHijvLlQdnYfYNd8afa1L1DBN66LC9AXQP2xB2bYVTlgr3zy6UzuMPCxBBzmRysovFdNaCwKAYY4ALeOowHFIMINOrBLXp_BwYr07nIUTSKWRHVXc6d3by0HzuxR7cIh_Zynn93ASM1-N5PONWzst6gcjh5pk4sCq6Tu3Vhr2CzP-isPfs8MmU58HNUZD53eMKZj5LdOEvCmG7b6IWjhO5cUuRGMpPEwTF3a11M",
              },
            }
          )
          .then(function (response) {
            console.log("Success: " + response.data);
            window.location.href = "/thank-you";
          })
          .catch(function (error) {
            console.log("Error: " + error.data);
          });
      }
    },
    onVerify: function (response) {
      if (response) {
        console.log("Verify: " + response);
        this.pleaseTickRecaptchaMessage = "";
        this.recaptchaVerified = true;
        this.recaptchaExpired = false;
      }
    },
    onExpired: function () {
      console.log("Recaptcha expired");
      this.recaptchaExpired = true;
    },
  },
  mounted() {
    var flagscroll = true;
    // Line animation on scroll
    $(window).on("scroll", function () {
      var top = $(window).scrollTop() + $(window).height(),
        isVisible = top > $("#contact-form-background").offset().top;
      if (flagscroll == true && isVisible) {
        flagscroll = false;
        $("#contact-form-background").toggleClass(
          "contact-form-animation",
          isVisible
        );
      }
    });
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

.hero #hero-3-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#contact-form-background {
  position: absolute;
  z-index: -100;
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-end;
}

.contact-form-container {
  width: 534px;
  margin-top: 50px;
  margin-bottom: 150px;
}

.recaptcha-wrapper {
  position: relative;
}

.recaptcha-error {
  color: #ff5252;
  line-height: 12px;
  word-break: break-word;
  hyphens: auto;
  position: absolute;
  top: 83px;
  left: 130px;
}

@media (max-width: 767.98px) {
  .contact-form-container {
    width: 450px;
  }

  .recaptcha-error {
    left: 90px;
  }
}

@media (max-width: 575.98px) {
  .contact-form-container {
    width: 100%;
  }

  .recaptcha-wrapper {
    width: 310px;
    margin-left: auto;
    margin-right: auto;
  }
  .recaptcha-error {
    left: 15px;
  }
}

@media (max-width: 420px) {
  .recaptcha-wrapper {
    width: auto;
  }
}
</style>

<style>
/* ------ filled ------ */
/* set height of text field */
.v-form
  .v-input.v-text-field.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-dirty)
  .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}

.v-form
  .v-input.v-text-field.v-text-field--filled
  .v-input__control
  .v-input__slot {
  min-height: 40px;
  max-height: 50px;
  margin-bottom: 5px;
  background: #f5f7f7;
}

/* set margin bottom after error message */
/* .v-form
  .v-input.v-text-field.v-text-field--filled.v-text-field--enclosed
  .v-text-field__details {
  margin-bottom: 10px;
} */

/* set position of text field label */
/* .v-form .v-input.v-text-field.v-text-field--filled .v-label {
  top: 15px;
} */

.v-form .v-input.v-text-field.v-text-field--filled .v-label,
.v-form .v-input.v-text-field.v-text-field--filled input {
  color: #2b2b2b;
  font-family: "Open Sans", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 15px;
}

/* set position of text field small label */
.v-form .v-input.v-text-field.v-text-field--filled .v-label--active {
  transform: translateY(-10px) scale(0.65);
  /* font-weight: bold; */
}

.v-form .v-input.v-text-field.v-text-field--filled input {
  margin-top: 18px;
  margin-bottom: 4px;
}

@media (max-width: 959px) {
  .v-form .v-input.v-text-field.v-text-field--filled .v-label,
  .v-form .v-input.v-text-field.v-text-field--filled input {
    font-size: 18px;
  }
}

@media (max-width: 599px) {
  .v-form .v-input.v-text-field.v-text-field--filled .v-label,
  .v-form .v-input.v-text-field.v-text-field--filled input {
    font-size: 17px;
  }
}

@media (max-width: 350px) {
  .v-form .v-input.v-text-field.v-text-field--filled .v-label,
  .v-form .v-input.v-text-field.v-text-field--filled input {
    font-size: 15px;
  }
}
</style>