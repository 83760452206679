<template>
  <svg
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="240.901"
    viewBox="0 0 1370.546 240.901"
  >
    <path
      id="Path_38"
      data-name="Path 38"
      d="M89.454,5890.849S203,5764.788,503.427,5764.788,975.616,5931.6,1166.091,5814.8,1460,5783.889,1460,5783.889v210.467H89.454Z"
      transform="translate(-89.454 -5753.456)"
      fill="#2B2B2B"
    />
    <animate
      xlink:href="#Path_38"
      repeatCount="indefinite"
      attributeName="d"
      attributeType="XML"
      values="M89.454,5817.776S203,5918.766,503.427,5918.766,865.7,5704.073,1166.091,5814.8,1460,5890.846,1460,5890.846v103.51H89.454Z;
                                    M89.454,5890.849S203,5764.788,503.427,5764.788,975.616,5931.6,1166.091,5814.8,1460,5783.889,1460,5783.889v210.467H89.454Z;
                            M89.454,5817.776S203,5918.766,503.427,5918.766,865.7,5704.073,1166.091,5814.8,1460,5890.846,1460,5890.846v103.51H89.454Z"
      dur="18s"
      fill="freeze"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
#footer-wave svg {
  position: absolute;
  height: 200px;
  bottom: -30px;
  left: 0;
  z-index: -100;
}

@media (max-width: 991.98px) {
  #footer-wave svg {
    height: 150px;
    bottom: -5px;
  }
}

@media (max-width: 767.98px) {
  #footer-wave svg {
    height: 100px;
  }
}
</style>