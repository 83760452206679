<template>
  <v-app>
    <Navbar v-if="!$route.meta.hideNavigation" />
    <v-main>
      <router-view />
    </v-main>
    <Footer v-if="!$route.meta.hideNavigation" />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: "Medwell",
  components: { Navbar, Footer },

  data: () => ({
    //
  }),
};
</script>
